import React from 'react';
import { Router, globalHistory } from "@reach/router"
import { QueryParamProvider } from 'use-query-params';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


import PrivateRoute from './components/PrivateRoute';

import Notifications from './components/Notifications';

import SignInPage from './pages/SignIn';
import ForgotPasswordPage from './pages/ForgotPassword';
import AgentsPage from './pages/Agents/Agents';
import CompanyProfilePage from './pages/CompanyProfile/CompanyProfile';
import InquiriesPage from './pages/Inquiries';
import OffersPage from './pages/Offers/Offers';
import SalesPage from './pages/Sales/Sales';
import MyProfilePage from './pages/MyProfile';
import ReviewsPage from './pages/Reviews/Reviews';
import InquiryDetails from './pages/InquiryDetails/InquiryDetails';

import DialogProvider from './components/DialogProvider';

import { trackPageView } from './analytics';

const onPageView = () => {
  setTimeout(() => {
    trackPageView();
  }, 50);
}


globalHistory.listen((args) => {
  onPageView();
});


onPageView();


const App: React.FC = () => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <QueryParamProvider reachHistory={globalHistory}>
        <DialogProvider>
          <SnackbarProvider maxSnack={3}>
            <Router>
              <SignInPage path="/sign-in" />
              <ForgotPasswordPage path="/forgot-password" />
              <PrivateRoute path="/" component={InquiriesPage} />
              <PrivateRoute path="/agents" component={AgentsPage} />
              <PrivateRoute path="/company-profile" component={CompanyProfilePage} />
              <PrivateRoute path="/offers" component={OffersPage} />
              <PrivateRoute path="/sales" component={SalesPage} />
              <PrivateRoute path="/my-profile" component={MyProfilePage} />
              <PrivateRoute path="/reviews" component={ReviewsPage} />
              <PrivateRoute path="/inquiry/:inqId" component={InquiryDetails} />
            </Router>
            <Notifications />
          </SnackbarProvider>
        </DialogProvider>
      </QueryParamProvider>
    </MuiPickersUtilsProvider>
  );
}

export default App;
