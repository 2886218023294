import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Grid, Button, Box, Avatar, Typography } from "@material-ui/core";

import { actions } from '../../services/company';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import ProgressButton from '../../components/ProgressButton';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      logo: {
        width: 250,
      }
    }
  ),
);

const TabLogo: React.FC = () => {
  const classes = useStyles();

  const dispatch = useDispatch();
  const loading = useSelector((state: RootState) => state.company.logoUploadLoading);
  const dealer = useSelector((state: RootState) => state.user.dealer);

  const [ imgSrc, setImgSrc ] = useState();
  const [ file, setFile ] = useState();
  const [ error, setError ] = useState('');

  const onChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      if (!validateFile(files[ 0 ])) {
        setFile(undefined);
        setImgSrc(undefined);
        return;
      }
      setFile(files[ 0 ]);
      const reader = new FileReader();
      reader.onload = (e) => {
        if (e.target && e.target.result) {
          setImgSrc(e.target.result);
        }
      }
      reader.readAsDataURL(files[ 0 ]);
    }
  }

  const validateFile = (file: File) => {
    if (![ 'image/png', 'image/jpg', 'image/jpeg' ].includes(file.type)) {
      setError('Invalid image type, JPG or PNG allowed.');
      return false;
    }

    if (file.size > 500 * 1024) {
      setError('Max file size is 500kb');
      return false;
    }

    setError('');

    return true;
  }

  const upload = () => {
    if (!file || !imgSrc || error !== '') {
      return;
    }

    dispatch(actions.uploadLogo(file));
  }

  const logoUrl = dealer && Boolean(dealer.logo) ? `${process.env.REACT_APP_CDN_BASE_MAIN}/${dealer.logo}` : null;

  return (
    <div className={classes.root}>
      <Grid container spacing={3} alignItems="center" justify="space-between">
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            {
              (imgSrc || logoUrl) ? <img src={imgSrc || logoUrl} alt="Logo" className={classes.logo} /> : null
            }
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center">
            <Button variant="outlined" component="label" disabled={loading}>
              Choose a file...
              <input
                type="file"
                style={{ display: "none" }}
                onChange={(e) => { onChange(e.target.files) }}
              /></Button>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Typography align="center" color="error">{error}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
            <ProgressButton
              variant="contained"
              color="primary"
              loading={loading}
              onClick={upload}
            >
              Upload
            </ProgressButton>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
};

export default TabLogo;