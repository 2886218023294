import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { LeaseOffer, firestoreGet, ModelTrim, DealerAgent, LeaseOfferPublic, firestoreQuery } from 'leasemojo-common';
import firebase, { db } from '../firebase';
import { LeaseOfferSaveArgs, LeaseOfferUpdateArgs } from '../typings/types';

import { actions as inquiryDetailsActions } from './inquiryDetails';
import { actions as notifications } from './notifications';


interface OffersState {
  loading: boolean;
  submitOfferPending: boolean;
}


const initialState: OffersState = {
  loading: false,
  submitOfferPending: false,
};


const offersService = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setOfferLoading(state, action: PayloadAction<boolean>): OffersState {
      return { ...state, loading: action.payload, }
    },
    setSubmitOfferPending(state, action: PayloadAction<boolean>): OffersState {
      return { ...state, submitOfferPending: action.payload, }
    },
  }
});

const submitOffer = (args: LeaseOfferSaveArgs): AppThunk => async (dispatch, getState): Promise<boolean> => {
  dispatch(offersService.actions.setSubmitOfferPending(true));
  const user = getState().user;
  if (!user.agent || !user.dealer) {
    return false;
  }

  //trimData is used for publicOffers until the server change is triggered
  const { trimData, ...rest } = args;

  const data = {
    status: 'active',
    dealer: user.dealer.id,
    agent: user.agent.id,
    createTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    updateTime: null,
    chatAllowed: true,
    chatMessages: 0,
    lastMessageTime: null,
    lastMessageSeenTimeAgent: null,
    lastMessageSeenTimeUser: null,
    unseenMessagesAgent: 0,
    unseenMessagesUser: 0,
    userSeenTime: null,
    published: false,
    ...rest,
  }

  try {
    const doc = await db.collection('offers').add(data);
    const publicOffer: LeaseOfferPublic = {
      id: doc.id,
      status: 'active',
      createTime: new Date().getTime(),
      expireTime: args.expireTime.getTime(),
      dealer: data.dealer,
      dealerData: user.dealer,
      trim: data.trim,
      trimData,
      leaseLength: data.leaseLength,
      downPayment: data.downPayment,
      mileage: data.mileage,
      monthlyPayment: data.monthlyPayment,
      taxIncluded: data.taxIncluded,
      unseenMessagesUser: 0,
      unseenMessagesAgent: 0,
    };
    dispatch(inquiryDetailsActions.insertPublicOffer(publicOffer));
    return true;
  }
  catch (e) {
    dispatch(notifications.show({ message: 'Error submitting offer', variant: 'error' }));
    console.error(e);
    return false;
  }
  finally {
    dispatch(offersService.actions.setSubmitOfferPending(false));
  }
}

const updateOffer = (args: LeaseOfferUpdateArgs): AppThunk => async (dispatch, getState): Promise<boolean> => {
  dispatch(offersService.actions.setSubmitOfferPending(true));
  const user = getState().user;
  if (!user.agent || !user.dealer) {
    return false;
  }

  try {
    const { id, inquiry, ...data } = args;
    const doc = db.collection('offers').doc(id);

    await doc.update({
      ...data,
      updateTime: firebase.firestore.FieldValue.serverTimestamp()
    });


    const publicOffer: Partial<LeaseOfferPublic> = {
      id: doc.id,
      expireTime: args.expireTime.getTime(),
      dealer: user.dealer.id,
      dealerData: user.dealer,
      trim: data.trim,
      leaseLength: data.leaseLength,
      downPayment: data.downPayment,
      mileage: data.mileage,
      monthlyPayment: data.monthlyPayment,
      taxIncluded: data.taxIncluded,
    };

    dispatch(inquiryDetailsActions.insertPublicOffer(publicOffer as LeaseOfferPublic));
    return true;
  }
  catch (e) {
    dispatch(notifications.show({ message: 'Error updating offer', variant: 'error' }));
    console.error(e);
    return false;
  }
  finally {
    dispatch(offersService.actions.setSubmitOfferPending(false));
  }
}

export const processOffer = async (snapshot: firebase.firestore.DocumentSnapshot) => {
  try {
    const offer = snapshot.data() as LeaseOffer;
    offer.id = snapshot.id;

    const trimRef = db.collection('cars').doc(offer.car).collection('models').doc(offer.model).collection('trims').doc(offer.trim);
    const agentRef = db.collection('dealers').doc(offer.dealer).collection('agents').doc(offer.agent);
    const result = await Promise.all([
      firestoreGet<ModelTrim>(trimRef, { cache: true }),
      firestoreGet<DealerAgent>(agentRef),
    ])
    if (result[ 0 ] && result[ 1 ]) {
      offer.trimData = result[ 0 ];
      offer.agentData = result[ 1 ];
      offer.agentData.createTime = (offer.agentData.createTime as firebase.firestore.Timestamp).seconds * 1000;
      offer.agentData.updateTime = offer.agentData.updateTime ? (offer.agentData.updateTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    }

    offer.createTime = offer.createTime ? (offer.createTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    offer.expireTime = offer.expireTime ? (offer.expireTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    offer.updateTime = offer.updateTime ? (offer.updateTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    offer.soldTime = offer.soldTime ? (offer.soldTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    offer.lastMessageSeenTimeAgent = offer.lastMessageSeenTimeAgent ? (offer.lastMessageSeenTimeAgent as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    offer.lastMessageSeenTimeUser = offer.lastMessageSeenTimeUser ? (offer.lastMessageSeenTimeUser as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    offer.lastMessageTime = offer.lastMessageTime ? (offer.lastMessageTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    offer.userSeenTime = offer.userSeenTime ? (offer.userSeenTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    return offer;
  }
  catch (e) {
    console.error(e);
    return null;
  }
}

const removeOffer = (id: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(offersService.actions.setOfferLoading(true));
    await db.collection('offers').doc(id).update({
      status: 'removed',
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
    // const dealer = getState().user.dealer;
    // if (dealer) {
    //   dispatch(inquiryDetailsActions.removePublicOffer(dealer.id));
    // }
  }
  catch (e) {
    console.error(e);
  }
}

const markAsSold = (offerId: string): AppThunk => async (dispatch, getState) => {
  try {
    await db.collection('offers').doc(offerId).update({
      status: 'sold',
      soldTime: firebase.firestore.FieldValue.serverTimestamp(),
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }
  catch (e) {
    console.error(e);
  }
}

const markAsActive = (offerId: string): AppThunk => async (dispatch, getState) => {
  try {
    await db.collection('offers').doc(offerId).update({
      status: 'active',
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }
  catch (e) {
    console.error(e);
  }
}

export const getTrims = async (car: string, model: string) => {
  const query = db.collection('cars').doc(car)
    .collection('models').doc(model)
    .collection('trims');
  const result = await firestoreQuery<ModelTrim>(query, { cache: true, cacheKey: `${car}-${model}-trims` });

  return result;
}

export const actions = {
  removeOffer,
  submitOffer,
  updateOffer,
  markAsSold,
  markAsActive,
};
export default offersService.reducer;