import firebase from "firebase/app"
import "firebase/auth"
import "firebase/firestore"
import "firebase/functions"
import "firebase/storage"
import "firebase/messaging"
import "firebase/analytics"


const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

firebase.initializeApp(firebaseConfig);


// firebase.firestore().enablePersistence();

export const db = firebase.firestore();

export const functions = firebase.functions();

export const messaging = firebase.messaging();

export const analytics = firebase.analytics();

if (process.env.REACT_APP_FIREBASE_MESSAGING_KEY) {
  console.log('messaging.usePublicVapidKey', process.env.REACT_APP_FIREBASE_MESSAGING_KEY);
  messaging.usePublicVapidKey(process.env.REACT_APP_FIREBASE_MESSAGING_KEY);
}

export default firebase;