import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AppThunk } from '../store';
import firebase, { db } from '../firebase';
import { ChatData, ChatMessage, LeaseOffer } from 'leasemojo-common';

import { actions as notifications } from './notifications';

interface ChatState {
  chats: {
    [ id: string ]: ChatData
  },
  loading: boolean;
  currentChat: string | null;
}



const initialState: ChatState = {
  chats: {},
  loading: false,
  currentChat: null,
};


const chatService = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): ChatState {
      return { ...state, loading: action.payload, }
    },
    setCurrentChat(state, action: PayloadAction<string | null>): ChatState {
      return { ...state, currentChat: action.payload, }
    },
    setMessages(state, action: PayloadAction<{ offerId: string, messages: ChatMessage[] }>): ChatState {
      return {
        ...state,
        chats: {
          ...state.chats,
          [ action.payload.offerId ]: {
            offerId: action.payload.offerId,
            messages: action.payload.messages,
          }
        }
      }
    },
    addMessage(state, action: PayloadAction<ChatMessage>): ChatState {
      const offerId = action.payload.offer;
      const current = state.chats[ offerId ] ? {
        ...state.chats[ offerId ]
      } : {
          offerId,
          messages: []
        };

      return {
        ...state,
        chats: {
          ...state.chats,
          [ offerId ]: {
            ...current,
            messages: [
              ...current.messages,
              action.payload,
            ]
          }
        }
      }
    },
  }
});


let isInitial = true;

const resetUnseenMessages = (offerId: string): AppThunk => async (dispatch, getState) => {
  try {
    await db.collection('offers').doc(offerId).update({
      unseenMessagesAgent: 0,
      lastMessageSeenTimeAgent: firebase.firestore.FieldValue.serverTimestamp(),
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });
  }
  catch (e) {
    console.error('resetUnseenMessages', e);
  }
}

const init = (): AppThunk => async (dispatch, getState) => {
  const agent = getState().user.agent;
  const dealer = getState().user.dealer;
  if (!agent || !dealer) {
    return;
  }

  try {
    firebase.firestore()
      .collection('chat')
      .where('agent', '==', agent.id)
      .orderBy('createTime', 'desc')
      .limit(5)
      .onSnapshot({
        next: (snapshot) => {
          const chat = getState().chat;
          if (isInitial) {
            isInitial = false;
            return;
          }

          snapshot.docChanges().forEach(item => {
            if (item.type !== 'added') {
              return;
            }

            const message = item.doc.data() as ChatMessage;
            message.id = item.doc.id;
            message.createTime = message.createTime ? (message.createTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
            if (chat.chats[ message.offer ]) {
              dispatch(chatService.actions.addMessage(message));
            }
            if (chat.currentChat !== message.offer) {
              dispatch(notifications.show({ message: 'New message', targetUrl: `/inquiry/${message.inquiry}?messages=1`, autoHideDuration: 5000 }));
            }
          })
        },
        error: (err) => {
          console.error(err);
        }
      });
  }
  catch (e) {
    console.error('chat init');
    console.error(e);
  }
}

const loadMessages = (offerId: string): AppThunk => async (dispatch, getState) => {
  const agent = getState().user.agent;
  const dealer = getState().user.dealer;

  const chat = getState().chat;

  if (!agent || !dealer || chat.chats[ offerId ]) {
    return;
  }

  try {
    dispatch(chatService.actions.setLoading(true));
    const result = await firebase.firestore()
      .collection('chat')
      .where('dealer', '==', dealer.id)
      .where('offer', '==', offerId)
      .orderBy('createTime', 'desc')
      .limit(100)
      .get();


    const messages: ChatMessage[] = [];

    result.docs.forEach(doc => {
      const message = doc.data() as ChatMessage;
      message.id = doc.id;
      message.createTime = message.createTime ? (message.createTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
      messages.unshift(message);
    });

    dispatch(chatService.actions.setMessages({
      offerId,
      messages
    }));
    dispatch(chatService.actions.setLoading(false));
  }
  catch (e) {
    console.error('chat.loadMessages', e);
  }
}

const sendMessage = (offer: LeaseOffer, message: string): AppThunk => async (dispatch, getState) => {
  try {
    const agent = getState().user.agent;
    const dealer = getState().user.dealer;

    if (!dealer || !agent) {
      return;
    }

    const doc: Partial<ChatMessage> = {
      createTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      from: agent.id,
      to: offer.user,
      offer: offer.id || '',
      inquiry: offer.inquiry,
      user: offer.user,
      agent: agent.id,
      dealer: dealer.id,
      message,
    }
    await firebase.firestore().collection('chat').add(doc);
  }
  catch (e) {
    console.error(e);
  }
}

export const actions = {
  init,
  sendMessage,
  loadMessages,
  setCurrentChat: chatService.actions.setCurrentChat,
  resetUnseenMessages,
};

export default chatService.reducer;