import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography } from "@material-ui/core";
import { Link } from "@reach/router";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      logo: {
        // fontFamily: '"Open Sans", Helvetica, sans-serif',
        display: 'inline-block',
      },
      logoBig: {
        fontWeight: 800,
        fontSize: 40,
        letterSpacing: 6,
      },
      logoNormal: {
        fontWeight: 600,
        fontSize: 20,
        letterSpacing: 3,
      },
      link: {
        color: '#fff',
        textDecoration: 'none',

        '&:active': {
          color: theme.palette.secondary.main,
        }
      }
    }
  ),
);

interface Props {
  className?: string;
  variant?: 'big' | 'normal';
  link?: boolean;
}

const Logo: React.FC<Props> = ({ className, variant = "normal", link = false }) => {
  const classes = useStyles();
  return (

    <Typography
      className={clsx(
        classes.logo, className,
        { [ classes.logoBig ]: variant === "big" },
        { [ classes.logoNormal ]: variant === "normal" }
      )}
      component="h2">
      {
        link ? <Link to="/" className={classes.link}>
          LEASEMOJO
        </Link> : 'LEASEMOJO'
      }
    </Typography>
  );
};

export default Logo;