import React, { useState, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, IconButton, Button } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Close';
import { PhotoSource } from "../../../typings/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      paper: {
        position: 'relative',
        width: 80,
        height: 150,
        display: 'flex',
        flex: '0 0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
      },
    }
  ),
);

interface Props {
  onAdd: (src: File) => void;
}

const PhotoItem: React.FC<Props> = ({ onAdd }) => {
  const classes = useStyles();

  const [ error, setError ] = useState();

  const onChange = (files: FileList | null) => {
    if (files && files.length === 1) {
      if (!validateFile(files[ 0 ])) {
        return;
      }
      onAdd(files[ 0 ]);

    }
  }

  const validateFile = (file: File) => {
    if (![ 'image/png', 'image/jpg', 'image/jpeg' ].includes(file.type)) {
      setError('Invalid image type, JPG or PNG allowed.');
      return false;
    }

    // if (file.size > 2000 * 1024) {
    //   setError('Max file size is 2mb');
    //   return false;
    // }

    setError('');

    return true;
  }


  return (
    <Paper className={classes.paper} elevation={0}>
      <IconButton component="label">
        <AddIcon />
        <input
          type="file"
          style={{ display: "none" }}
          onChange={(e) => { onChange(e.target.files) }}
        />
      </IconButton>
    </Paper>
  );
};

export default PhotoItem;