import React from "react";
import { RouteComponentProps } from "@reach/router";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper } from "@material-ui/core";
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import Layout from "../../components/Layout";

import TabCompany from './TabCompany';
import TabAddress from './TabAddress';
import TabLogo from './TabLogo';
import { useQueryParam, NumberParam } from "use-query-params";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      paper: {
      },
      tabContent: {
        padding: theme.spacing(4, 2, 3, 2),
      }
    }
  ),
);

const CompanyProfilePage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const [ tab = 0, setTab ] = useQueryParam('tab', NumberParam);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTab(newValue);
  };

  const getContent = () => {
    switch (tab) {
      case 0:
        return <TabCompany />;
      case 1:
        return <TabAddress />;
      case 2:
        return <TabLogo />;
      default:
        return <TabCompany />;
    }
  }

  return (
    <Layout title="Company Profile" contentSize="sm">
      <Paper className={classes.paper}>
        <AppBar position="static" color="default" elevation={0}>
          <Tabs
            value={tab}
            onChange={handleTabChange}
            textColor="primary"
            indicatorColor="primary"
            variant="fullWidth"
          >
            <Tab label="Company Info" />
            <Tab label="Address" />
            <Tab label="Logo" />
          </Tabs>
        </AppBar>
        <div className={classes.tabContent}>
          {getContent()}
        </div>
      </Paper>
    </Layout>
  );
};

export default CompanyProfilePage;