import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { Button, Box } from '@material-ui/core';
import { Link } from '@reach/router';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import Logo from '../components/Logo';
import { RouteComponentProps } from '@reach/router';

import ProgressButton from '../components/ProgressButton';

import { actions } from '../services/user';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    logo: {
      // marginBottom: theme.spacing(2),
    }
  }),
);

const ForgotPasswordPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  
  const [ email, setEmail ] = useState('');
  const [ inProgress, setInProgress ] = useState(false);
  const [ done, setDone ] = useState(false);



  const onSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (inProgress) {
      return;
    }

    if (email) {
      setInProgress(true);
      const result = await actions.sendPasswordResetLink(email);
      console.log(result);
      setInProgress(false);
      setDone(true);
    }

    return;
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  return (
    <Container component="main" maxWidth="xs">
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Logo variant="normal" className={classes.logo} />
        <Typography paragraph>Forgot Password</Typography>
        {
          !done ? (
            <form className={classes.form} noValidate onSubmit={onSubmit}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                onChange={onEmailChange}
                value={email}
                autoFocus
                disabled={inProgress}
              />

              <ProgressButton
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
                loading={inProgress}
              >
                Send password reset link
          </ProgressButton>
            </form>
          ) : (
              <Box display="flex" flexDirection="column" justifyContent="center">
                <Typography paragraph align="center">If the email you entered is correct, You will receive an email with instructions on how to reset your password.</Typography>
                <Button component={Link} to="/sign-in" variant="outlined">
                  Continue to Sign In
                </Button>
              </Box>
            )
        }
      </div>
    </Container>
  );
}

export default ForgotPasswordPage;