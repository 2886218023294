import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { isEmail } from 'validator';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, useTheme, TextField, Box } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';



import { DealerAgent } from "leasemojo-common";
import MaskedInput from "../../components/MaskedInput";
import ProgressButtton from "../../components/ProgressButton";

import { actions } from '../../services/agents';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
      },
      title: {
        padding: theme.spacing(2),
      },
      content: {

      },
      bottom: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
      }
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  variant: 'add' | 'edit';
  data?: DealerAgent;
  loading: boolean;
}

const AgentAddEditModal: React.FC<Props> = ({ open, onClose, variant, data, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();


  const [ formState, setFormState ] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
  });

  const [ changePassword, setChangePassword ] = useState(false);

  const onSetChangePassword = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChangePassword(event.target.checked)
  };

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    setFormState({
      name: data ? data.name : '',
      email: data ? data.email : '',
      phone: data ? data.phone : '',
      password: '',
    });

    console.log('useEffect');
  }, [ data && data.id ])

  const [ errors, setErrors ] = useState({
    phone: '',
    email: '',
    name: '',
    password: '',
  });

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [ name ]: event.target.value });
  };

  const validate = () => {
    const _err = {
      phone: '',
      email: '',
      name: '',
      password: '',
    };
    let valid = true;
    const _phone = formState.phone.replace(/ /g, '').replace(/_/g, '')
    if (Boolean(formState.phone) && _phone.length !== 12) {
      _err.phone = 'Invalid phone';
      valid = false;
    }
    if (!formState.email || !isEmail(formState.email)) {
      _err.email = 'Invalid email';
      valid = false;
    }
    if (!formState.name) {
      _err.name = 'Name is required';
      valid = false;
    }

    if ((variant === 'add' || (variant === 'edit' && changePassword)) && formState.password.length < 6) {
      _err.password = 'Password should be at least 6 charecters';
      valid = false;
    }

    setErrors(_err);
    return valid;
  }

  const onSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (validate()) {
      let result;
      if (variant === 'add') {
        result = await dispatch(actions.addAgent({
          ...formState,
          phone: formState.phone.replace(/ /g, '').replace(/_/g, '')
        }));
      }
      if (variant === 'edit' && data) {
        result = await dispatch(actions.updateAgent({
          ...formState,
          agentId: data.id,
          phone: formState.phone.replace(/ /g, '').replace(/_/g, '')
        }));
      }

      if (!result) {
        return;
      }

      setFormState({
        name: '',
        email: '',
        phone: '',
        password: '',
      });

      onClose();
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.root}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.title}>
        {variant === 'add' ? 'Add agent' : 'Edit agent'}
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <form onSubmit={onSubmitForm} noValidate>
          <div>
            <TextField
              fullWidth
              id="standard-basic"
              label="Name"
              margin="normal"
              variant="outlined"
              value={formState.name}
              onChange={handleChange('name')}
              disabled={loading}
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name : null}
              required
            />
          </div>
          <div>
            <MaskedInput
              mask="+1 999 999 9999"
              variant="outlined"
              margin="normal"
              fullWidth
              label="Phone number"
              name="phone-number"
              autoComplete="phone"
              value={formState.phone}
              onChange={handleChange('phone')}
              disabled={loading}
              error={Boolean(errors.phone)}
              helperText={errors.phone ? errors.phone : null}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Email"
              margin="normal"
              variant="outlined"
              value={formState.email}
              onChange={handleChange('email')}
              disabled={loading}
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email : null}
              required
            />
          </div>
          {
            variant === 'edit' ? (
              <Box display="flex" justifyContent="flex-end">
                <FormControlLabel
                  control={
                    <Switch checked={changePassword} onChange={onSetChangePassword} value="checkedA" />
                  }
                  label="Update password"
                />
              </Box>

            ) : null
          }

          <TextField
            fullWidth
            type="password"
            id="outlined-basic"
            label="Password"
            margin="normal"
            variant="outlined"
            value={formState.password}
            onChange={handleChange('password')}
            error={Boolean(errors.password)}
            helperText={errors.password ? errors.password : null}
            required
            disabled={!(variant === 'add' || (variant === 'edit' && changePassword)) || loading}
          />
          <div className={classes.bottom}>
            <ProgressButtton type="submit" variant="contained" color="primary" loading={loading}>
              {variant === 'add' ? 'Add Agent' : 'Save Changes'}
            </ProgressButtton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AgentAddEditModal;