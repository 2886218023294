import React, { useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Toolbar, Typography, Box, Divider, CircularProgress } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/Layout";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import Rating from '../../components/Rating';
import ReviewItem from "./ReviewItem";

import { actions } from '../../services/reviews';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
    }
  ),
);

const Reviews: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dealer = useSelector((state: RootState) => state.user.dealer);
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state.reviews);

  useEffect(() => {
    dispatch(actions.load());
  }, []);


  if (!dealer) {
    return null;
  }

  return (
    <Layout title="Reviews" contentSize="md" loading={state.loading}>
      <Box display="flex" justifyContent="space-between" mb={2} alignItems="center">
        <Typography variant="h5">
          {dealer.name}
        </Typography>
        <Rating value={dealer.rating} readonly large/>
        <Typography>{dealer.reviewCount} reviews</Typography>
      </Box>
      <Divider />

      <Box mt={2}>
        {
          state.reviews.map(item => <ReviewItem key={item.id} data={item} />)
        }
      </Box>

    </Layout>
  );
};

export default Reviews;