import React, { useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Fab } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import Layout from "../../components/Layout";
import { useQueryParam, BooleanParam } from "use-query-params";

import AddSaleModal from './AddSaleModal';
import { actions } from '../../services/sales';
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";


import SaleItemCard from './SaleItemCard';


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      },
      addIcon: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
      }
    }
  ),
);

const SalesPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  const [ modalOpen = false, setModalOpen ] = useQueryParam('dialog', BooleanParam);
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.sales);

  useEffect(() => {
    dispatch(actions.load());
  }, []);

  const openAddModal = () => {
    setModalOpen(true, 'pushIn');
  }

  const closeModal = () => {
    setModalOpen(false, 'replace');
  }

  return (
    <Layout title="Published Sales" loading={state.loading}>
      <div className={classes.root}>
        {
          state.list.map((item) => <SaleItemCard key={item.id} data={item} />)
        }
      </div>
      <Fab color="primary" className={classes.addIcon} onClick={openAddModal}>
        <AddIcon />
      </Fab>
      <AddSaleModal open={modalOpen} onClose={closeModal}  />
    </Layout>
  );
};

export default SalesPage;