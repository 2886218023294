import { combineReducers, configureStore, Action } from '@reduxjs/toolkit';
import { ThunkAction } from 'redux-thunk';

import user from './services/user';
import notifications from './services/notifications';
import inquiries from './services/inquiries';
import inquiryDetails from './services/inquiryDetails';
import offer from './services/offer';
import allOffers from './services/allOffers';
import company from './services/company';
import agents from './services/agents';
import chat from './services/chat';
import reviews from './services/reviews';
import publishSale from './services/publishSale';
import sales from './services/sales';

const rootReducer = combineReducers({
  user,
  notifications,
  inquiries,
  offer,
  company,
  agents,
  allOffers,
  chat,
  inquiryDetails,
  reviews,
  publishSale,
  sales,
});

export type RootState = ReturnType<typeof rootReducer>;

export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export type AppThunkBool = ThunkAction<Promise<boolean>, RootState, null, Action<string>>;


const store = configureStore({
  reducer: rootReducer
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('./store', () => {
    store.replaceReducer(rootReducer);
  });
}

export type AppDispatch = typeof store.dispatch;
export default store;