import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Base64 } from 'js-base64';
import { DealerAgent, Dealer } from 'leasemojo-common';
import firebase, { db, messaging } from '../firebase';
import { AppThunk} from '../store';

import { actions as chatActions } from './chat';
import { actions as inquiriesActions } from './inquiries';
import { actions as notificationActions } from './notifications';

interface UserState {
  agent: DealerAgent | null;
  dealer: Dealer | null;
  userDataLoading: boolean;
  loginInProgress: boolean;
  updateProfileInProgress: boolean;
  reAuthInProgress: boolean;
  reAuthOpen: boolean;
  reAuthError: boolean;
}


const initialState: UserState = {
  agent: null,
  dealer: null,
  userDataLoading: true,
  loginInProgress: false,
  updateProfileInProgress: false,
  reAuthInProgress: false,
  reAuthOpen: false,
  reAuthError: false,
};

const fetchAgentProfile = async (user: firebase.User): Promise<{ agent: DealerAgent, dealer: Dealer } | null> => {
  const dealerId = user.uid.split('_')[ 0 ];

  const dealerDocRef = db.collection('dealers').doc(dealerId);
  const agentDocRef = dealerDocRef.collection('agents').doc(user.uid);

  const dealerDoc = await dealerDocRef.get();
  const agentDoc = await agentDocRef.get();


  if (!agentDoc.exists || !dealerDoc.exists) {
    return null;
  }
  else {
    const dealer = dealerDoc.data() as Dealer;
    dealer.id = dealerId;
    dealer.createTime = (dealer.createTime as firebase.firestore.Timestamp).seconds * 1000;
    dealer.updateTime = dealer.updateTime ? (dealer.updateTime as firebase.firestore.Timestamp).seconds * 1000 : null;


    const agent = agentDoc.data() as DealerAgent;
    agent.id = user.uid;
    agent.createTime = (agent.createTime as firebase.firestore.Timestamp).seconds * 1000;
    agent.updateTime = agent.updateTime ? (agent.updateTime as firebase.firestore.Timestamp).seconds * 1000 : null;
    return {
      agent,
      dealer
    };
  }
};


const userService = createSlice({
  name: 'user',
  initialState,
  reducers: {
    resetState() {
      return { 
        ...initialState
      }
    },
    setLoginInProgress(state, action: PayloadAction<boolean>) {
      return { ...state, loginInProgress: action.payload }
    },
    setUpdateProfileInProgress(state, action: PayloadAction<boolean>) {
      return { ...state, updateProfileInProgress: action.payload }
    },
    setReauthInProgress(state, action: PayloadAction<boolean>) {
      return { ...state, reAuthInProgress: action.payload }
    },
    setReAuthOpen(state, action: PayloadAction<boolean>) {
      return { ...state, reAuthOpen: action.payload }
    },
    setReAuthError(state, action: PayloadAction<boolean>) {
      return { ...state, reAuthError: action.payload }
    },
    setUserData(state, action: PayloadAction<{ agent: DealerAgent | null, dealer: Dealer | null }>) {
      return {
        ...state,
        agent: action.payload.agent,
        dealer: action.payload.dealer,
        userDataLoading: false,
      }
    },
    updateProfileDone(state, action: PayloadAction<DealerAgent>) {
      return {
        ...state,
        agent: action.payload,
      }
    }
  }
});

const savePushToken = (token: string): AppThunk => async (dispatch, getState) => {
  const user = getState().user;
  if (!user.agent || !user.dealer) {
    return;
  }

  const deviceId = Base64.encode(navigator.userAgent);
  const pushTokens = user.agent.pushTokens ? {
    ...user.agent.pushTokens
  } : {
    };

  pushTokens[ deviceId ] = {
    deviceId: navigator.userAgent,
    token
  }

  await db.collection('dealers').doc(user.dealer.id)
    .collection('agents').doc(user.agent.id).update({
      pushTokens,
      updateTime: firebase.firestore.FieldValue.serverTimestamp(),
    });

  console.log('push token saved', token);
}

const requestPushPermission = (): AppThunk => async (dispatch, getState) => {
  if ('Notification' in window) {
    Notification.requestPermission().then((permission) => {
      if (permission === 'granted') {
        console.log('Notification permission granted.');
        messaging.getToken().then((refreshedToken) => {
          if (refreshedToken) {
            dispatch(savePushToken(refreshedToken));
          }
        }).catch((err) => {
          console.error('Unable to retrieve refreshed token ', err);
        });
      } else {
        console.error('Unable to get permission to notify.');
      }
    });
  }
}

const initPushNotifications = (): AppThunk => async (dispatch, getState) => {

  messaging.onTokenRefresh(() => {
    messaging.getToken().then((refreshedToken) => {
      console.log('Token refreshed.', refreshedToken);
      if (refreshedToken) {
        dispatch(savePushToken(refreshedToken));
      }
    }).catch((err) => {
      console.error('Unable to retrieve refreshed token ', err);
    });
  });

}


const init = (): AppThunk => async dispatch => {
  firebase.auth().onAuthStateChanged(async (fUser) => {
    if (fUser) {
      const user = await fetchAgentProfile(fUser);
      if (user === null) {
        firebase.auth().signOut();
      }
      else {
        console.log(user);
        dispatch(onLoginDone(user));
      }
    } else {
      dispatch(actions.setUserData({
        agent: null,
        dealer: null
      }));
    }
  });
}

let loginDone = false;

const onLoginDone = (data: { agent: DealerAgent, dealer: Dealer }): AppThunk => async dispatch => {
  if (loginDone) {
    return;
  }
  loginDone = true;
  
  dispatch(actions.setUserData(data));
  dispatch(inquiriesActions.loadInquiries());
  dispatch(chatActions.init());
  dispatch(initPushNotifications());
  dispatch(requestPushPermission());
}

const signOut = (): AppThunk => async dispatch => {
  await firebase.auth().signOut();
  window.location.reload(true);
}

const signIn = (email: string, password: string): AppThunk => async dispatch => {
  dispatch(actions.setLoginInProgress(true));
  try {
    const result = await firebase.auth().signInWithEmailAndPassword(email, password);
    if (result.user) {

      const user = await fetchAgentProfile(result.user);
      if (user) {
        dispatch(onLoginDone(user));
        return true;
      }
    }
    return false;
  }
  catch (e) {
    return false;
  }
  finally {
    dispatch(actions.setLoginInProgress(false));
  }
}

interface UpdateProfileArgs {
  name: string;
  email: string;
  phone: string;
  password?: string;
}

let _tmpUpdateArgs: UpdateProfileArgs;

const updateProfile = (args: UpdateProfileArgs): AppThunk => async (dispatch, getState) => {

  const agent = getState().user.agent;
  const dealer = getState().user.dealer;
  const currentUser = firebase.auth().currentUser;

  if (!agent || !dealer || !currentUser) {
    return;
  }


  try {
    dispatch(actions.setUpdateProfileInProgress(true));
    if (args.email !== agent.email) {
      await currentUser.updateEmail(args.email);
    }

    if (args.password) {
      await currentUser.updatePassword(args.password);
    }

    await db.collection('dealers').doc(dealer.id)
      .collection('agents')
      .doc(agent.id)
      .update({
        name: args.name,
        phone: args.phone,
        email: args.email,
        updateTime: firebase.firestore.FieldValue.serverTimestamp(),
      });

    const newProfile: DealerAgent = {
      ...agent,
      name: args.name,
      phone: args.phone,
      email: args.email,
    }

    dispatch(actions.updateProfileDone(newProfile));
    dispatch(notificationActions.show({
      message: 'Profile saved',
    }));
  }
  catch (e) {
    if (e.code === 'auth/requires-recent-login') {
      _tmpUpdateArgs = { ...args };
      dispatch(actions.setReAuthOpen(true));
      return;
    }
    console.error(e);
    dispatch(notificationActions.show({
      message: e.message,
      variant: 'error',
      autoHideDuration: 5000,
    }));
  }
  finally {
    dispatch(actions.setUpdateProfileInProgress(false));
  }
}

const reAuth = (password: string): AppThunk => async (dispatch, getState) => {
  const agent = getState().user.agent;
  if (!agent) {
    return;
  }

  const credential = firebase.auth.EmailAuthProvider.credential(agent.email, password);
  try {
    dispatch(actions.setReauthInProgress(true));
    dispatch(actions.setReAuthError(false));
    await firebase.auth().signInWithCredential(credential);
    dispatch(actions.setReAuthOpen(false));
    if (_tmpUpdateArgs) {
      dispatch(actions.updateProfile(_tmpUpdateArgs));
    }
  }
  catch (e) {
    dispatch(actions.setReAuthError(true));
  }
  finally {
    dispatch(actions.setReauthInProgress(false));
  }
}

const sendPasswordResetLink = async (email: string) => {
  try {
    await firebase.auth().sendPasswordResetEmail(email)
    return true;
  }
  catch (e) {
    return false;
  }
}

export const actions = {
  ...userService.actions,
  init,
  signOut,
  signIn,
  updateProfile,
  sendPasswordResetLink,
  reAuth,
  requestPushPermission,
  initPushNotifications,
};
export default userService.reducer;