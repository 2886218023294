import React, { useEffect } from "react";

import find from 'lodash/find';
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Fab,
  Typography,
  CircularProgress
} from "@material-ui/core";
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';

import { RouteComponentProps } from "@reach/router";
import Layout from "../../components/Layout";

import { useSelector, useDispatch } from "react-redux";
import { useQueryParams, BooleanParam, StringParam } from "use-query-params";

import { actions } from '../../services/agents';
import { RootState } from "../../store";
import { timeAgo, DealerAgent } from "leasemojo-common";
import AgentAddEditModal from './AgentAddEditModal';

import { useDialog } from '../../components/DialogProvider';



const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      paper: {
        width: '100%',
        overflowX: 'auto',
      },
      table: {
        minWidth: 1000,
      },
      addIcon: {
        position: 'fixed',
        bottom: theme.spacing(5),
        right: theme.spacing(5),
      }
    }
  ),
);

const AgentsPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => state.agents);
  const user = useSelector((state: RootState) => state.user);
  const myself = useSelector((state: RootState) => state.user.agent);

  const [ params, setParams ] = useQueryParams({
    edit: BooleanParam,
    add: BooleanParam,
    id: StringParam
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (state.agents.length === 0) {
      dispatch(actions.loadList());
    }
  }, []);

  const dialog = useDialog();

  if (!myself) {
    return null;
  }

  const closeModal = () => {
    window.history.back();
  }

  const openAddModal = () => {
    setParams({ add: true }, 'pushIn');
  }

  const openEditModal = (id: string) => {
    setParams({ edit: true, id }, 'pushIn');
  }

  const onDelete = (agent: DealerAgent) => {
    dialog.confirm({
      title: `Delete ${agent.name}?`,
      subtitle: `You can't undo this. All active offers that ${agent.name} has will be removed.`,
      onConfirm() {
        dispatch(actions.deleteAgent(agent.id));
      }
    })
  }

  let selectedAgent = find(state.agents, { id: params.id });

  return (
    <Layout title="Agents" loading={state.loadingList}>
      <Fab color="primary" variant="extended" className={classes.addIcon} onClick={openAddModal}>
        <AddIcon />
        Add Agent
      </Fab>
      <Typography paragraph>Total agents: {state.agents.length}</Typography>
      <Paper className={classes.paper}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell align="right">Total Offers</TableCell>
              <TableCell align="right">Active Offfers</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">Updated</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.agents.map(row => {
              const disableActions = user.agent && row.id === user.agent.id ? true : false;
              return (
                <TableRow key={row.id}>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{row.phone}</TableCell>
                  <TableCell align="right">{row.totalOffers}</TableCell>
                  <TableCell align="right">{row.activeOffers}</TableCell>
                  <TableCell align="right">{timeAgo(row.createTime as number)}</TableCell>
                  <TableCell align="right">{row.updateTime ? timeAgo(row.updateTime as number) : ''}</TableCell>
                  <TableCell align="right">
                    {
                      state.deleteInProgress.includes(row.id) ? (
                        <CircularProgress />
                      ) : (
                          <React.Fragment>
                            <IconButton
                              disabled={disableActions}
                              onClick={() => {
                                openEditModal(row.id);
                              }}> <EditIcon /> </IconButton>
                            <IconButton
                              disabled={disableActions}
                              onClick={() => {
                                onDelete(row);
                              }}> <DeleteIcon /> </IconButton>

                          </React.Fragment>
                        )
                    }
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Paper>
      <AgentAddEditModal
        open={Boolean(params.add || params.edit)}
        variant={params.add ? 'add' : 'edit'}
        data={selectedAgent}
        onClose={closeModal}
        loading={state.loadingOther}
      />
    </Layout>
  );
};

export default AgentsPage;