import React, { useState, useEffect, useMemo } from "react";

import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  useTheme,
  InputLabel,
  Select,
  FormControl,
  Box,
  TextField,
  OutlinedInput,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  MenuItem,
  FormHelperText,
} from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import { DatePicker } from '@material-ui/pickers';

import { OfferFormData } from "../../typings/types";

import ProgressButton from '../../components/ProgressButton';

import {
  LeaseLengthLabels,
  LeaseMileageLabels,
  DownPaymentLabels,
  ModelTrim,
  InquiryData,
} from "leasemojo-common";

import { getTrims } from '../../services/offer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
      },
      title: {
        padding: theme.spacing(2),
      },
      content: {

      },
      formControl: {
        width: '100%',
        marginBottom: theme.spacing(3),
      },
      addSpaceR: {
        marginRight: theme.spacing(1),
      },
      addSpaceL: {
        marginLeft: theme.spacing(1),
      },
      bottom: {
        marginTop: theme.spacing(1),
        textAlign: 'center',
      },
      btnSend: {
        width: 200,
      }
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: OfferFormData) => void;
  pending: boolean;
  inquiry: InquiryData;
  data: OfferFormData;
  variant: 'new' | 'update';
  readOnly?: boolean;
}

interface Errors {
  monthlyPayment: string;
};


const SendOfferModal: React.FC<Props> = ({ open, onClose, data, variant, onSubmit, inquiry, pending, readOnly }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [ trims, setTrims ] = useState<ModelTrim[]>([]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (open) {
      getTrims(inquiry.car, inquiry.model)
        .then(trims => {
          setTrims(trims);
        });
    }
  }, [ open, inquiry.car, inquiry.model ]);


  const [ formState, setFormState ] = useState<OfferFormData>({ ...data });


  const [ errors, setErrors ] = useState<Errors>({
    monthlyPayment: ''
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  let currTrim = useMemo<ModelTrim | null>(() => {
    for (let i = 0; i < trims.length; i++) {
      if (trims[ i ].id === formState.trim) {
        return trims[ i ];
      }
    }
    return null;
  }, [ formState.trim, trims.length ]);

  const menuExtColors: React.ReactElement[] = currTrim ? currTrim.exteriorColors.map(item => (
    <MenuItem key={item.id} value={item.id}>{`${item.name} ($${item.msrp})`}</MenuItem>
  )) : [];
  const menuIntColors: React.ReactElement[] = currTrim ? currTrim.interiorColors.map(item => (
    <MenuItem key={item.id} value={item.id}>{`${item.name} ($${item.msrp})`}</MenuItem>
  )) : [];


  let minExpireTime = new Date();
  minExpireTime.setHours(minExpireTime.getHours() + 24);
  if (data.expireTime && data.expireTime < minExpireTime) {
    minExpireTime = data.expireTime;
  }

  const maxExpireTime = new Date();
  maxExpireTime.setDate(maxExpireTime.getDate() + 60);

  const onExpireTimeChange = (date: any) => {
    setFormState({
      ...formState,
      expireTime: date
    })
  }

  const handleSelect = (name: string) => (event: React.ChangeEvent<{ value: unknown }>) => {
    setFormState({ ...formState, [ name ]: event.target.value });
  };
  const handleTextField = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [ name ]: event.target.value });
  };
  const handleCheckBox = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [ name ]: event.target.checked });
  };

  const menuLeaseLength: React.ReactElement[] = [];
  LeaseLengthLabels.forEach((value, key) => {
    if (key === 'not_sure') {
      return;
    }
    menuLeaseLength.push(<MenuItem key={key} value={key}>{value}</MenuItem>);
  });
  const menuMileage: React.ReactElement[] = [];
  LeaseMileageLabels.forEach((value, key) => {
    if (key === 'not_sure') {
      return;
    }
    menuMileage.push(<MenuItem key={key} value={key}>{value}</MenuItem>);
  });
  const menuDownPayment: React.ReactElement[] = [];
  DownPaymentLabels.forEach((value, key) => {
    if (key === 'not_sure') {
      return;
    }
    menuDownPayment.push(<MenuItem key={key} value={key}>{value}</MenuItem>);
  });

  const validateForm = () => {
    if (formState.monthlyPayment <= 0) {
      setErrors({ monthlyPayment: 'Invalid payment amount' });
      return false;
    }

    return true;
  }

  const onSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (validateForm()) {
      onSubmit(formState);
    }
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.root}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.title}>
        {variant === 'new' ? 'Send offer' : 'Update the offer'} for {inquiry.carData && inquiry.carData.name} {inquiry.modelData && inquiry.modelData.name}
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <form onSubmit={onSubmitForm}>
          <FormControl className={classes.formControl} variant="outlined" disabled={readOnly}>
            <InputLabel htmlFor="trim">Trim</InputLabel>
            <Select
              value={formState.trim}
              onChange={handleSelect('trim')}
              labelWidth={35}
              inputProps={{
                name: 'trim',
                id: 'trim',
              }}
            >
              {trims.map(trim => (
                <MenuItem key={trim.id} value={trim.id}>{`${trim.name} ($${trim.msrp})`}</MenuItem>
              ))}
            </Select>
          </FormControl>

          <Box display="flex" flexDirection="row">

            <FormControl className={clsx(classes.formControl, classes.addSpaceR)} variant="outlined" disabled={readOnly}>
              <InputLabel htmlFor="exterior">Exterior</InputLabel>
              <Select
                onChange={handleSelect('exteriorColor')}
                value={formState.exteriorColor}
                labelWidth={55}
                inputProps={{
                  name: 'exterior',
                  id: 'exterior',
                }}
              >
                {menuExtColors}
              </Select>
            </FormControl>

            <FormControl className={clsx(classes.formControl, classes.addSpaceL)} variant="outlined" disabled={readOnly}>
              <InputLabel htmlFor="interior">Interior</InputLabel>
              <Select
                value={formState.interiorColor}
                onChange={handleSelect('interiorColor')}
                labelWidth={50}
                inputProps={{
                  name: 'interior',
                  id: 'interior',
                }}
              >
                {menuIntColors}
              </Select>
            </FormControl>

          </Box>

          <FormControl className={classes.formControl} variant="outlined" disabled={readOnly}>
            <InputLabel htmlFor="lease-length">Lease Length</InputLabel>
            <Select
              value={formState.leaseLength}
              onChange={handleSelect('leaseLength')}
              labelWidth={100}
              inputProps={{
                name: 'lease-length',
                id: 'lease-length',
              }}
            >
              {menuLeaseLength}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined" disabled={readOnly}>
            <InputLabel htmlFor="down-payment">Down Payment</InputLabel>
            <Select
              value={formState.downPayment}
              onChange={handleSelect('downPayment')}
              labelWidth={110}
              inputProps={{
                name: 'down-payment',
                id: 'down-payment',
              }}
            >
              {menuDownPayment}
            </Select>
          </FormControl>

          <FormControl className={classes.formControl} variant="outlined" disabled={readOnly}>
            <InputLabel htmlFor="Mileage">Mileage</InputLabel>
            <Select
              value={formState.mileage}
              onChange={handleSelect('mileage')}
              labelWidth={55}
              inputProps={{
                name: 'Mileage',
                id: 'Mileage',
              }}
            >
              {menuMileage}
            </Select>
          </FormControl>


          <Box display="flex" flexDirection="row">
            <FormControl className={clsx(classes.formControl, classes.addSpaceR)} variant="outlined" disabled={readOnly}>
              <InputLabel htmlFor="monthly-payment">Monthly Payment</InputLabel>
              <OutlinedInput
                error={errors.monthlyPayment !== ''}
                value={formState.monthlyPayment}
                onChange={handleTextField('monthlyPayment')}
                id="monthly-payment"
                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                labelWidth={125}
              />
              {errors.monthlyPayment !== '' ? <FormHelperText error={true}>{errors.monthlyPayment}</FormHelperText> : null}
            </FormControl>


            <FormControl className={classes.formControl} variant="outlined" disabled={readOnly}>
              <DatePicker
                value={formState.expireTime}
                onChange={onExpireTimeChange}
                variant="dialog"
                inputVariant="outlined"
                label="Offer expires in"
                disablePast={true}
                maxDate={maxExpireTime}
                minDate={minExpireTime}
                disabled={readOnly}
              />
            </FormControl>
          </Box>

          <FormControlLabel
            className={clsx(classes.formControl, classes.addSpaceL)}
            control={
              <Checkbox
                disabled={readOnly}
                onChange={handleCheckBox('taxIncluded')}
                value={true}
                checked={formState.taxIncluded}
                color="secondary"
              />
            }
            label="Tax included"
          />

          <FormControl className={classes.formControl} variant="outlined" disabled={readOnly}>
            <TextField
              variant="outlined"
              label="Additional notes"
              value={formState.additionalNotes}
              onChange={handleTextField('additionalNotes')}
              multiline
              rowsMax={6}
              rows={2}
              disabled={readOnly}
            />
          </FormControl>

          <div className={classes.bottom}>
            <ProgressButton
              type="submit"
              variant="contained"
              color="primary"
              className={classes.btnSend}
              disabled={readOnly || pending}
              loading={pending}>
              {variant === 'new' ? 'Send' : 'Update'}
            </ProgressButton>
          </div>

        </form>

      </DialogContent>
    </Dialog>
  );
};

export default SendOfferModal;