import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { ModelTrim } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      img: {
        width: 80,
        height: 40,
      },
    }
  ),
);

interface Props {
  trim: ModelTrim;
  size?: number;
  color: number;
  placeholder?: string;
}

const TrimImage: React.FC<Props> = ({ size, trim, color, placeholder }) => {
  const classes = useStyles();
  const styles = size ? {
    width: size,
    height: size / 2,
  } : {};

  let colorIndex = 0;
  for (let i = 0; i < trim.exteriorColors.length; i++) {
    if (color === trim.exteriorColors[ i ].id) {
      colorIndex = i;
      break;
    }
  }


  const imgUrl = trim.exteriorColors[ colorIndex ].images.md ? `${process.env.REACT_APP_CDN_BASE_CARS}/${trim.exteriorColors[ colorIndex ].images.md}` : `${process.env.REACT_APP_CDN_BASE_CARS}/${placeholder}`;

  return (
    <img className={classes.img} alt={trim.name} src={imgUrl} style={styles} />
  );
};

export default TrimImage;