import React from "react";

import { TextField } from "@material-ui/core";
import InputMask, { Props as ReactMaskProps } from 'react-input-mask';
import { TextFieldProps } from "@material-ui/core/TextField";



const InputComp: React.FC<ReactMaskProps> = ({ mask, ...props }) => {
  return <InputMask {...props} mask={mask} />
}

interface Props {
  mask: string;
}
const MaskedInput: React.FC<Props & TextFieldProps> = ({ mask, ...props }) => {
  return (
    /* eslint-disable react/jsx-no-duplicate-props */
    <TextField
      {...props}
      InputProps={{
        inputComponent: InputComp as any,
      }}
      inputProps={{
        mask: mask,
      }}
    />
  );
};

export default MaskedInput;