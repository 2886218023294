import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { DealerReview, fixTimeStamps, attachUsers } from 'leasemojo-common';
import firebase, { db } from '../firebase';


let loaded = false;

interface ReviewsState {
  loading: boolean;
  reviews: DealerReview[];
}


const initialState: ReviewsState = {
  loading: false,
  reviews: [],
};


const reviewsService = createSlice({
  name: 'reviews',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): ReviewsState {
      return { ...state, loading: action.payload, }
    },
    setReviews(state, action: PayloadAction<DealerReview[]>): ReviewsState {
      return { ...state, reviews: [ ...action.payload ], }
    },
  }
});

const load = (reload?: boolean): AppThunk => async (dispatch, getState) => {
  try {
    if (loaded && !reload) {
      return;
    }
    
    dispatch(reviewsService.actions.setLoading(true));
    const dealer = getState().user.dealer;
    if (!dealer) {
      return;
    }

    const result = await firebase.firestore()
      .collection('reviews')
      .where('dealer', '==', dealer.id)
      .where('status', '==', 'active')
      .orderBy('updateTime', 'desc')
      .limit(50)
      .get();

    loaded = true;

    let reviews: DealerReview[] = [];

    result.forEach(doc => {
      let review = doc.data() as DealerReview;
      review.id = doc.id;
      review = fixTimeStamps(review);
      reviews.push(review);
    })

    reviews = await attachUsers(reviews, firebase.firestore());
    dispatch(reviewsService.actions.setReviews(reviews));

  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(reviewsService.actions.setLoading(false));
  }
}

export const actions = {
  load,
};
export default reviewsService.reducer;