import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import orderBy from 'lodash/orderBy';
import {
  LeaseOffer,
  ModelTrim,
  CarMake,
  CarModel,
  DealerAgent,
} from 'leasemojo-common';
import firebase, { db } from '../firebase';
import { firestoreLookup, OfferStatus } from 'leasemojo-common';

import { actions as agentActions } from './agents';

interface AllOffersState {
  offers: LeaseOffer[],
  loading: boolean;
}


const initialState: AllOffersState = {
  offers: [],
  loading: true,
};


const offersService = createSlice({
  name: 'offers',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): AllOffersState {
      return { ...state, loading: action.payload, }
    },
    reset(state, action: PayloadAction): AllOffersState {
      return { ...state, offers: [], }
    },
    offerAdded(state, action: PayloadAction<LeaseOffer>): AllOffersState {
      const newList = orderBy([
        action.payload,
        ...state.offers,
      ], 'createTime', 'desc');

      return {
        ...state,
        offers: newList,
      }
    },
    offerUpdated(state, action: PayloadAction<ProcessOfferResult>): AllOffersState {
      const newList = state.offers.map(item => item.id === action.payload.offer.id ? action.payload.offer : item);

      return {
        ...state,
        offers: newList,
      }
    },
    offerRemoved(state, action: PayloadAction<string>): AllOffersState {
      const newList = state.offers.filter(item => item.id !== action.payload);

      return {
        ...state,
        offers: newList,
      }
    },
  }
});


interface LoadAllOffersArgs {
  agentId?: string,
  status?: OfferStatus,
}

interface ProcessOfferResult {
  offer: LeaseOffer;
  type: firebase.firestore.DocumentChangeType;
  oldIndex: number;
  index: number;
}


const processOffers = async (snapshot: firebase.firestore.QuerySnapshot): Promise<ProcessOfferResult[]> => {
  const carRefs: firebase.firestore.DocumentReference[] = [];
  const modelRefs: firebase.firestore.DocumentReference[] = [];
  const trimRefs: firebase.firestore.DocumentReference[] = [];
  const agentRefs: firebase.firestore.DocumentReference[] = [];

  const result: ProcessOfferResult[] = [];

  snapshot.docChanges().forEach(item => {
    const offer = item.doc.data() as LeaseOffer;
    offer.id = item.doc.id;
    if (item.type !== 'removed') {
      offer.createTime = offer.createTime ? (offer.createTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
      offer.updateTime = offer.updateTime ? (offer.updateTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
      offer.expireTime = offer.expireTime ? (offer.expireTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
      offer.lastMessageSeenTimeAgent = offer.lastMessageSeenTimeAgent ? (offer.lastMessageSeenTimeAgent as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
      offer.lastMessageSeenTimeUser = offer.lastMessageSeenTimeUser ? (offer.lastMessageSeenTimeUser as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
      offer.lastMessageTime = offer.lastMessageTime ? (offer.lastMessageTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
      offer.soldTime = offer.soldTime && (offer.soldTime as firebase.firestore.Timestamp).seconds * 1000;
      offer.userSeenTime = offer.userSeenTime ? (offer.userSeenTime as firebase.firestore.Timestamp).seconds * 1000 : null;
      const carRef = db.collection('cars').doc(offer.car);
      const modelRef = carRef.collection('models').doc(offer.model);
      const trimRef = modelRef.collection('trims').doc(offer.trim);
      const agentRef = db.collection('dealers').doc(offer.dealer).collection('agents').doc(offer.agent);

      carRefs.push(carRef);
      modelRefs.push(modelRef);
      trimRefs.push(trimRef);
      agentRefs.push(agentRef);
    }

    result.push({
      offer,
      type: item.type,
      oldIndex: item.oldIndex,
      index: item.newIndex,
    });
  });

  const cars = firestoreLookup<CarMake>(carRefs, { cache: true });
  const models = firestoreLookup<CarModel>(modelRefs, { cache: true });
  const trims = firestoreLookup<ModelTrim>(trimRefs, { cache: true });
  const agents = firestoreLookup<DealerAgent>(agentRefs, { cache: true, cacheExpire: '6h' });

  const offerData = await Promise.all([ cars, models, trims, agents ]);

  return result.map(item => {
    if (item.type !== 'removed') {
      item.offer.carData = offerData[ 0 ][ item.offer.car ];
      item.offer.modelData = offerData[ 1 ][ item.offer.model ];
      item.offer.trimData = offerData[ 2 ][ item.offer.trim ];
      item.offer.agentData = offerData[ 3 ][ item.offer.agent ];
      item.offer.agentData.createTime = (item.offer.agentData.createTime as firebase.firestore.Timestamp).seconds * 1000;
      item.offer.agentData.updateTime = item.offer.agentData.updateTime ? (item.offer.agentData.updateTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
    }
    return item;
  });
}

let _lastArgs: LoadAllOffersArgs;
let cancelLive: any;

const loadAllOffers = (args: LoadAllOffersArgs): AppThunk => async (dispatch, getState) => {
  const state = getState();
  if (!state.user.agent || !state.user.dealer) {
    return;
  }

  if (JSON.stringify(_lastArgs) === JSON.stringify(args)) {
    return;
  }

  if (cancelLive) {
    cancelLive();
    cancelLive = null;
  }

  dispatch(offersService.actions.reset());

  _lastArgs = { ...args };

  if (state.agents.agents.length === 0) {
    dispatch(agentActions.loadList());
  }

  let ref = db.collection('offers')
    .where('dealer', '==', state.user.dealer.id);

  if (args.agentId) {
    ref = ref.where('agent', '==', args.agentId)
  }

  if (args.status) {
    ref = ref.where('status', '==', args.status)
  }

  ref.orderBy('createTime', 'desc');

  cancelLive = ref.onSnapshot({
    next: async (snapshot) => {
      if (state.allOffers.loading) {
        dispatch(offersService.actions.setLoading(false));
      }

      const offers = await processOffers(snapshot);

      offers.forEach(offer => {
        if (offer.type === 'added') {
          dispatch(offersService.actions.offerAdded(offer.offer));
        }
        else if (offer.type === 'modified') {
          dispatch(offersService.actions.offerUpdated(offer));
        }
        else if (offer.type === 'removed' && offer.offer.id) {
          dispatch(offersService.actions.offerRemoved(offer.offer.id));
        }
      });
    },
    error: (err) => {
      console.error(err);
    }
  });
}


export const actions = {
  loadAllOffers,
};

export default offersService.reducer;