import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import firebase, { db } from '../firebase';

import { actions as notification } from './notifications';
import {
  CarMake,
  CarModel,
  ModelTrim,
  LeaseLength,
  DownPayment,
  LeaseMileage,
  CreditScore,
  firestoreGet,
  LeaseOffer,
  InquiryData,
  SoldPhotoData,
  SoldListingItem
} from 'leasemojo-common';
import { getCars, getModels, getTrims } from '../utils/carData';

import find from 'lodash/find';
import uniqueId from '../utils/uniqueId';

interface FormState {
  id?: string;
  car?: string;
  model?: string;
  trim?: string;
  interiorColor?: number;
  exteriorColor?: number;
  leaseLength?: LeaseLength;
  downPayment?: DownPayment;
  mileage?: LeaseMileage;
  creditScore?: CreditScore;
  monthlyPayment?: string;
  zip?: string;
  photos?: SoldPhotoData[];
  offer?: string;
  inquiry?: string;
}

interface PublishedSalesState {
  loading: boolean;
  savePending: boolean;
  cars: CarMake[];
  models: CarModel[];
  trims: ModelTrim[];
  photosLoadingProgress: {
    [ index: number ]: number
  }
  form: FormState;
}

const initialState: PublishedSalesState = {
  loading: false,
  savePending: false,
  cars: [],
  models: [],
  trims: [],
  photosLoadingProgress: {},
  form: {},
};


const service = createSlice({
  name: 'publishSale',
  initialState,
  reducers: {
    setForm(state, action: PayloadAction<FormState>): PublishedSalesState {
      return {
        ...state,
        form: action.payload,
      }
    },
    setLoading(state, action: PayloadAction<boolean>): PublishedSalesState {
      return { ...state, loading: action.payload, }
    },
    setSavePending(state, action: PayloadAction<boolean>): PublishedSalesState {
      return { ...state, savePending: action.payload, }
    },
    setCars(state, action: PayloadAction<CarMake[]>): PublishedSalesState {
      return {
        ...state,
        cars: [
          ...action.payload,
        ],
      }
    },
    setModels(state, action: PayloadAction<CarModel[]>): PublishedSalesState {
      return {
        ...state,
        models: [
          ...action.payload,
        ],
      }
    },
    setTrims(state, action: PayloadAction<ModelTrim[]>): PublishedSalesState {
      return {
        ...state,
        trims: [
          ...action.payload,
        ],
      }
    },
    setPhotoProgress(state, action: PayloadAction<{ index: number, progress: number | null }>): PublishedSalesState {
      const photos = { ...state.photosLoadingProgress };
      if (action.payload.progress === null) {
        delete photos[ action.payload.index ];
      }
      else {
        photos[ action.payload.index ] = action.payload.progress;
      }
      return {
        ...state,
        photosLoadingProgress: photos,
      }
    },
  }
});

const loadCars = (): AppThunk => async (dispatch, getState) => {
  dispatch(service.actions.setCars([]));
  dispatch(service.actions.setModels([]));
  dispatch(service.actions.setTrims([]));
  try {
    const cars = await getCars();
    dispatch(service.actions.setCars(cars));
  }
  catch (e) {
    console.error(e);
  }
}

const loadModels = (carId: string): AppThunk => async (dispatch, getState) => {
  dispatch(service.actions.setModels([]));
  dispatch(service.actions.setTrims([]));
  try {
    const models = await getModels(carId);
    dispatch(service.actions.setModels(models));
  }
  catch (e) {
    console.error(e);
  }
}

const loadTrims = (carId: string, modelId: string): AppThunk => async (dispatch, getState) => {
  dispatch(service.actions.setTrims([]));
  try {
    const trims = await getTrims(carId, modelId);
    dispatch(service.actions.setTrims(trims));
  }
  catch (e) {
    console.error(e);
  }
}

const resetForm = (): AppThunk => async (dispatch, getState) => {
  dispatch(service.actions.setForm({}));
}

const delay = (duration: number) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, duration);
  });
}

export const loadOffer = async (offerId: string) => {
  const result = await firestoreGet<LeaseOffer>(db.collection('offers').doc(offerId));
  return result;
}

const loadInquiry = async (inqId: string) => {
  const inq = await firestoreGet<InquiryData>(db.collection('inquiries').doc(inqId));
  return inq;
}

export const loadListingItem = async (id: string) => {
  const result = firestoreGet<SoldListingItem>(db.collection('sales').doc(id));
  return result;
}

const loadFromOffer = (offerId: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(service.actions.setLoading(true));
    dispatch(resetForm());


    const offer = await loadOffer(offerId);
    await dispatch(loadCars());

    if (offer && offer.id) {
      await dispatch(loadModels(offer.car));
      await dispatch(loadTrims(offer.car, offer.model));
      const inquiry = await loadInquiry(offer.inquiry);

      const form: FormState = {
        car: offer.car,
        model: offer.model,
        trim: offer.trim,
        interiorColor: offer.interiorColor,
        exteriorColor: offer.exteriorColor,
        leaseLength: offer.leaseLength,
        downPayment: offer.downPayment,
        mileage: offer.mileage,
        creditScore: inquiry?.creditScore,
        monthlyPayment: offer.monthlyPayment.toString(),
        zip: inquiry?.zip.toString(),
        photos: [],
        offer: offer.id,
        inquiry: offer.inquiry,
      }
      dispatch(service.actions.setForm(form));
    }
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setLoading(false));
  }
}

const loadFromId = (id: string): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(service.actions.setLoading(true));
    dispatch(resetForm());


    const data = await loadListingItem(id);
    dispatch(loadCars());

    if (data) {
      dispatch(loadModels(data.car));
      dispatch(loadTrims(data.car, data.model));

      const form: FormState = {
        id,
        car: data.car,
        model: data.model,
        trim: data.trim,
        interiorColor: data.interiorColor,
        exteriorColor: data.exteriorColor,
        leaseLength: data.leaseLength,
        downPayment: data.downPayment,
        mileage: data.mileage,
        creditScore: data.creditScore,
        monthlyPayment: data.monthlyPayment.toString(),
        zip: data.zip.toString(),
        photos: data.photos,
        offer: data.offer ? data.offer : undefined,
        inquiry: data.inquiry ? data.inquiry : undefined,
      }
      dispatch(service.actions.setForm(form));
    }
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setLoading(false));
  }
}

interface UploadPhotoArgs {
  dealerId: string;
  saleListingId: string;
  photos: File[];
  onUpdate: (index: number, progress: number) => void;
}

const uploadPhotos = async ({ dealerId, saleListingId, photos, onUpdate }: UploadPhotoArgs): Promise<SoldPhotoData[]> => {
  const storage = firebase.storage().ref();

  return new Promise((resolve, reject) => {
    const result: SoldPhotoData[] = [];
    let count = 0;

    for (let i = 0; i < photos.length; i++) {
      const file = photos[ i ];
      const ext = file.name.toLowerCase().split('.').splice(-1)[ 0 ];
      const timeStamp = new Date().getTime();
      const fileRef = storage.child(`/sold/${dealerId}/${saleListingId}/${i}-${timeStamp}.${ext}`);
      const task = fileRef.put(file);

      task.on('state_changed', (snapshot) => {
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onUpdate(i, progress);
      }, (error) => {
        reject(error);
      }, async () => {
        const url = await task.snapshot.ref.getDownloadURL();
        result[ i ] = {
          original: url,
          path: fileRef.fullPath,
        };
        count += 1;
        console.log('count', count, photos.length);
        if (count === photos.length) {
          resolve(result);
        }
      });
    }
  });
}

const save = (argPhotos: File[]): AppThunk => async (dispatch, getState) => {

  dispatch(service.actions.setSavePending(true));

  const dealer = getState().user.dealer;
  const agent = getState().user.agent;
  const state = getState().publishSale;
  const form = state.form as Required<FormState>;
  if (!dealer || !agent) {
    return;
  }

  try {

    const trimData = typeof form.trim !== 'undefined' ? find(state.trims, { id: form.trim }) : null;

    if (!trimData) {
      throw new Error('Invalid trimData');
    }

    let saleListingId = form.id;

    const doc: Partial<SoldListingItem> = {
      status: 'pending',
      dealer: dealer.id,
      agent: agent.id,
      offer: form.offer || null,
      inquiry: form.inquiry || null,
      car: form.car,
      model: form.model,
      trim: form.trim,
      exteriorColor: form.exteriorColor,
      interiorColor: form.interiorColor,
      leaseLength: form.leaseLength,
      downPayment: form.downPayment,
      mileage: form.mileage,
      creditScore: form.creditScore,
      monthlyPayment: parseInt(form.monthlyPayment),
      zip: parseInt(form.zip),
      taxIncluded: true,
    }


    if (saleListingId) {
      doc.photos = form.photos || [];
      doc.updateTime = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
      await db.collection('sales').doc(saleListingId).update(doc);
    }
    else {
      doc.photos = [];
      doc.createTime = firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp;
      const res = await db.collection('sales').add(doc);
      saleListingId = res.id;
    }


    let newPhotos: SoldPhotoData[] = [];
    if (argPhotos.length > 0) {
      for (let i = 0; i < argPhotos.length; i++) {
        dispatch(service.actions.setPhotoProgress({ index: i, progress: 0 }));
      }
      newPhotos = await uploadPhotos({
        dealerId: dealer.id,
        saleListingId,
        photos: argPhotos,
        onUpdate: (index, progress) => {
          dispatch(service.actions.setPhotoProgress({ index, progress }));
        }
      })

      await db.collection('sales').doc(saleListingId).update({
        status: 'pending',
        updateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        photos: [
          ...form.photos || [],
          ...newPhotos,
        ]
      })

      for (let i = 0; i < argPhotos.length; i++) {
        dispatch(service.actions.setPhotoProgress({ index: i, progress: null }));
      }
    }

  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setSavePending(false));
  }
}


const removePhoto = (index: number): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(service.actions.setPhotoProgress({ index, progress: 0 }));
    const form = getState().publishSale.form;
    const currPhotos = form.photos;
    const id = form.id;

    if (currPhotos && id) {
      const photos = currPhotos.filter((photo, i) => i !== index);
      await db.collection('sales').doc(id).update({
        status: 'pending',
        updateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
        photos,
      })
      dispatch(service.actions.setForm({
        ...form,
        photos
      }))
    }
  }
  catch (e) {
    console.error(e);
  }
  finally {
    dispatch(service.actions.setPhotoProgress({ index, progress: null }));
  }
}


export const actions = {
  loadCars,
  loadModels,
  loadTrims,
  save,
  setForm: service.actions.setForm,
  loadFromOffer,
  loadFromId,
  resetForm,
  removePhoto,
};
export default service.reducer;