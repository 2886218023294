import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import firebase, { db } from '../firebase';

import { actions as notification } from './notifications';

import {
  SoldListingItem, fixTimeStamps, firestoreLookup, CarMake, CarModel, ModelTrim, DealerAgent
} from 'leasemojo-common';


interface SalesState {
  loading: boolean;
  list: SoldListingItem[];
}

const initialState: SalesState = {
  loading: false,
  list: [],
};


const service = createSlice({
  name: 'publishSale',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): SalesState {
      return { ...state, loading: action.payload, }
    },
    addItem(state, action: PayloadAction<SoldListingItem>): SalesState {
      return {
        ...state,
        list: [
          action.payload,
          ...state.list,
        ]
      }
    },
    updateItem(state, action: PayloadAction<SoldListingItem>): SalesState {
      return {
        ...state,
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return action.payload;
          }
          return item;
        })
      }
    },
    removeItem(state, action: PayloadAction<SoldListingItem>): SalesState {
      return {
        ...state,
        list: state.list.filter(item => item.id !== action.payload.id),
      }
    },
  }
});

let cancelLive: any;

const load = (): AppThunk => async (dispatch, getState) => {
  if (cancelLive) {
    return;
  }

  try {
    const dealer = getState().user.dealer;
    if (!dealer) {
      return;
    }

    dispatch(service.actions.setLoading(true));

    cancelLive = db.collection('sales')
      .where('status', 'in', [ 'pending', 'active' ])
      .where('dealer', '==', dealer.id)
      .orderBy('createTime', 'desc')
      .limit(100)
      .onSnapshot({
        next: async (snapshot) => {
          if (getState().sales.loading) {
            dispatch(service.actions.setLoading(false));
          }

          const result = await processItems(snapshot);

          for (let i = result.length - 1; i >= 0; i--) {
            if (result[ i ].type === 'added') {
              dispatch(service.actions.addItem(result[ i ].item));
            }
            else if (result[ i ].type === 'modified') {
              dispatch(service.actions.updateItem(result[ i ].item));
            }
            else if (result[ i ].type === 'removed') {
              dispatch(service.actions.removeItem(result[ i ].item));
            }
          }

        },
        error: (e) => {
          console.error(e);
        }
      })
  }
  catch (e) {
    console.error(e);
  }
}


const remove = (id: string): AppThunk => async (dispatch, getState) => {
  try {
    console.log('removing', id);
    await db.collection('sales').doc(id).update({
      status: 'deleted',
      updateTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
      deleteTime: firebase.firestore.FieldValue.serverTimestamp() as firebase.firestore.Timestamp,
    })
  }
  catch (e) {
    console.error(e);
    dispatch(notification.show({ message: e.message, variant: 'error' }));
  }
}




interface ProcessItemResult {
  oldIndex: number;
  newIndex: number;
  item: SoldListingItem,
  type: firebase.firestore.DocumentChangeType,
}

const processItems = async (snapshot: firebase.firestore.QuerySnapshot) => {
  const carRefs: firebase.firestore.DocumentReference[] = [];
  const modelRefs: firebase.firestore.DocumentReference[] = [];
  const trimRefs: firebase.firestore.DocumentReference[] = [];
  const agentRefs: firebase.firestore.DocumentReference[] = [];

  const result: ProcessItemResult[] = [];
  snapshot.docChanges().forEach(doc => {
    const item = fixTimeStamps(doc.doc.data()) as SoldListingItem;
    item.id = doc.doc.id;

    const carRef = db.collection('cars').doc(item.car);
    const modelRef = carRef.collection('models').doc(item.model);
    const trimRef = modelRef.collection('trims').doc(item.trim);
    const agentRef = db.collection('dealers').doc(item.dealer).collection('agents').doc(item.agent);

    carRefs.push(carRef);
    modelRefs.push(modelRef);
    trimRefs.push(trimRef);
    agentRefs.push(agentRef);

    result.push({
      item,
      oldIndex: doc.oldIndex,
      newIndex: doc.newIndex,
      type: doc.type,
    });
  });

  const cars = firestoreLookup<CarMake>(carRefs, { cache: true });
  const models = firestoreLookup<CarModel>(modelRefs, { cache: true });
  const trims = firestoreLookup<ModelTrim>(trimRefs, { cache: true });
  const agents = firestoreLookup<DealerAgent>(agentRefs, { cache: true, cacheExpire: '6h' });

  const data = await Promise.all([ cars, models, trims, agents ]);

  return result.map(item => {
    if (item.type !== 'removed') {
      item.item.carData = data[ 0 ][ item.item.car ];
      item.item.modelData = data[ 1 ][ item.item.model ];
      item.item.trimData = data[ 2 ][ item.item.trim ];
      item.item.agentData = fixTimeStamps(data[ 3 ][ item.item.agent ]);
    }
    return item;
  });
}


export const actions = {
  load,
  remove,
};
export default service.reducer;