import React from "react";

import {
  makeStyles,
  createStyles
} from "@material-ui/styles";

import {
  Theme,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Typography,
} from "@material-ui/core";

import {
  timeAgo,
  LeaseLengthLabels,
  LeaseMileageLabels,
  DownPaymentLabels,
} from "leasemojo-common";

import { useSelector } from "react-redux";
import { RootState } from "../../store";

import orderBy from 'lodash/orderBy';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      tableContainer: {
        maxWidth: 'calc(100vw - 40px)',
        overflowX: 'auto',
        textAlign: 'center',
        border: '1px solid #eee'
      },
      table: {
        minWidth: 1000,
      },
      offerDetailsContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      },
    }
  ),
);


const PublicOffers: React.FC = () => {
  const classes = useStyles();

  const inquiry = useSelector((state: RootState) => state.inquiryDetails.inquiry);
  const dealer = useSelector((state: RootState) => state.user.dealer);
  const agent = useSelector((state: RootState) => state.user.agent);


  if (!inquiry || !dealer || !agent) {
    return null;
  }

  const offers = orderBy(Object.values(inquiry.offers), [ 'createTime' ], [ 'desc' ])
    .filter(item => item.dealer !== dealer.id);


  return (

    <div className={classes.root}>
      <Typography variant="subtitle1">Other offers</Typography>
      <div className={classes.tableContainer}>
        <Table className={classes.table}>
          <TableHead>
            <TableRow>
              <TableCell>Dealer</TableCell>
              <TableCell align="right">Time</TableCell>
              <TableCell align="right">Trim</TableCell>
              <TableCell align="right">Lease Length</TableCell>
              <TableCell align="right">Down Payment</TableCell>
              <TableCell align="right">Mileage</TableCell>
              <TableCell align="right">Monthly</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {offers.map((row, index) => (
              <TableRow selected={dealer.id === row.dealer} key={'offer-' + index}>
                <TableCell component="th" scope="row">
                  {row.dealerData && row.dealerData.name}
                </TableCell>
                <TableCell align="right">{timeAgo(row.createTime as number)}</TableCell>
                <TableCell align="right">{row.trimData && row.trimData.name}</TableCell>
                <TableCell align="right">{LeaseLengthLabels.get(row.leaseLength)}</TableCell>
                <TableCell align="right">{DownPaymentLabels.get(row.downPayment)}</TableCell>
                <TableCell align="right">{LeaseMileageLabels.get(row.mileage)}</TableCell>
                <TableCell align="right">${row.monthlyPayment}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
};

export default PublicOffers;