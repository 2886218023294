import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase, { db } from '../firebase';
import { AppThunk } from '../store';

import { processOffer } from './offer';

import {
  firestoreLookup,
  CarMake,
  CarModel,
  fixTimeStamps,
} from 'leasemojo-common';


import {
  InquiryData,
  LeaseOffer,
  LeaseOfferPublic,
  Dealer,
  ModelTrim,
} from 'leasemojo-common';

interface InquiryDetailsState {
  inquiry: InquiryData | null;
  activeOffer: LeaseOffer | null;
  loading: boolean,
}

const initialState: InquiryDetailsState = {
  inquiry: null,
  activeOffer: null,
  loading: false,
};

let cancelUpdates: any;
let cancelActiveOfferUpdates: any;

const inquiryService = createSlice({
  name: 'inquiries',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): InquiryDetailsState {
      return { ...state, loading: action.payload }
    },
    resetState(): InquiryDetailsState {
      return {
        ...initialState,
        loading: true,
      }
    },
    setInquiryData(state, action: PayloadAction<InquiryData>): InquiryDetailsState {
      return {
        ...state,
        inquiry: action.payload,
      }
    },
    setActiveOffer(state, action: PayloadAction<LeaseOffer | null>): InquiryDetailsState {
      return {
        ...state,
        activeOffer: action.payload,
      }
    },
    removePublicOffer(state, action: PayloadAction<string>): InquiryDetailsState {
      if (!state.inquiry) {
        return { ...state };
      }
      const offers = { ...state.inquiry.offers };
      delete offers[ action.payload ];
      return {
        ...state,
        activeOffer: null,
        inquiry: {
          ...state.inquiry,
          offers,
        }
      }
    },
    insertPublicOffer(state, action: PayloadAction<LeaseOfferPublic>): InquiryDetailsState {
      if (!state.inquiry) {
        return { ...state };
      }
      return {
        ...state,
        inquiry: {
          ...state.inquiry,
          offers: {
            ...state.inquiry.offers,
            [ action.payload.dealer ]: {
              ...state.inquiry.offers[ action.payload.dealer ],
              ...action.payload
            }
          }
        }
      }
    }
  }
});

export const stopLiveUpdates = () => {
  if (cancelUpdates) {
    cancelUpdates();
    cancelUpdates = null;
  }
  if (cancelActiveOfferUpdates) {
    cancelActiveOfferUpdates();
    cancelActiveOfferUpdates = null;
  }
}

const loadData = (inqId: string): AppThunk => async (dispatch, getState) => {
  const ref = db.collection('inquiries').doc(inqId);

  stopLiveUpdates();

  dispatch(inquiryService.actions.resetState());


  cancelUpdates = ref.onSnapshot({
    next: async (doc) => {
      const dealer = getState().user.dealer;

      if (!dealer) {
        return;
      }

      const inquiry = fixTimeStamps(doc.data()) as InquiryData;
      inquiry.id = doc.id;

      const carRef = db.collection('cars').doc(inquiry.car);
      const modelRef = carRef.collection('models').doc(inquiry.model);
      const trimRef = modelRef.collection('trims').doc(inquiry.trim);

      const car = firestoreLookup<CarMake>([ carRef ], { cache: true });
      const model = firestoreLookup<CarModel>([ modelRef ], { cache: true });
      const trim = firestoreLookup<ModelTrim>([ trimRef ], { cache: true });

      const carData = await Promise.all([ car, model, trim ]);
      inquiry.carData = carData[ 0 ][ inquiry.car ];
      inquiry.modelData = carData[ 1 ][ inquiry.model ];
      inquiry.trimData = carData[ 2 ][ inquiry.trim ];
      let activeOfferId;

      for (let dealerId in inquiry.offers) {
        if (dealerId === dealer.id) {
          activeOfferId = inquiry.offers[ dealerId ].id;
        }
        inquiry.offers[ dealerId ] = fixTimeStamps(inquiry.offers[ dealerId ]);
      }

      inquiry.offers = {
        ...await processPublicOffers(inquiry)
      }
      dispatch(inquiryService.actions.setInquiryData(inquiry));
      if (activeOfferId) {
        dispatch(watchActiveOffer(activeOfferId));
      }
      dispatch(inquiryService.actions.setLoading(false));
    },
    error: (err) => {
      console.log('inquiryDetails.loadData', err);
    }
  });
}

const watchActiveOffer = (offerId: string): AppThunk => async (dispatch, getState) => {
  cancelActiveOfferUpdates = db.collection('offers')
    .doc(offerId)
    .onSnapshot({
      next: async (snapshot) => {
        const offer = await processOffer(snapshot);
        if (offer) {
          dispatch(inquiryService.actions.setActiveOffer(offer));
        }
        else {
          dispatch(inquiryService.actions.setActiveOffer(null));
        }
      },
      error: (err) => {
        dispatch(inquiryService.actions.setActiveOffer(null));
        console.error('inquiryDetails.watchActiveOffer', err);
      }
    })
}

const processPublicOffers = async (inquiry: InquiryData): Promise<{ [ dealerId: string ]: LeaseOfferPublic }> => {
  const trimRefs = [];
  const dealerRefs = [];

  for (let dealerId in inquiry.offers) {
    if (!inquiry.offers[ dealerId ].dealerData || !inquiry.offers[ dealerId ].trimData) {
      trimRefs.push(db.collection('cars').doc(inquiry.car).collection('models').doc(inquiry.model).collection('trims').doc(inquiry.trim));
      dealerRefs.push(db.collection('dealers').doc(dealerId));
    }
  }


  if (trimRefs.length > 0 && dealerRefs.length > 0) {
    const result: { [ dealerId: string ]: LeaseOfferPublic } = {

    };

    const dealers = await firestoreLookup<Dealer>(dealerRefs, { cache: true, cacheExpire: '6h' });
    const trims = await firestoreLookup<ModelTrim>([ trimRefs[ 0 ] ], { cache: true });

    for (let dealerId in inquiry.offers) {
      result[ dealerId ] = {
        ...inquiry.offers[ dealerId ],
      }

      if (!result[ dealerId ].dealerData) {
        result[ dealerId ].dealerData = dealers[ inquiry.offers[ dealerId ].dealer ];
      }
      if (!result[ dealerId ].trimData) {
        result[ dealerId ].trimData = trims[ inquiry.offers[ dealerId ].trim ]
      }
    }
    return result;
  }
  return { ...inquiry.offers };
}


export const actions = {
  loadData,
  insertPublicOffer: inquiryService.actions.insertPublicOffer,
  removePublicOffer: inquiryService.actions.removePublicOffer,
};
export default inquiryService.reducer;