import React, { useEffect } from "react";
import { Link } from '@reach/router';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import distanceInWordsToNow from 'date-fns/formatDistanceToNow';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';



import { RootState } from '../../store';

import { RouteComponentProps } from "@reach/router";
import { useQueryParam } from 'use-query-params';
import Layout from "../../components/Layout";

import { actions } from '../../services/allOffers';
import Filters from './OffersFilters';

import CarLogo from '../../components/CarLogo';
import ModelImage from '../../components/ModelImage';

import {
  LeaseMileageLabels,
  DownPaymentLabels,
  LeaseLengthLabels,
} from 'leasemojo-common';



const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      table: {
        minWidth: 1000,
      },
      spacer: {
        width: theme.spacing(1),
      },
      imgCellH: {
        display: 'flex',
        alignItems: 'center',
        '& img': {
          marginRight: theme.spacing(1),
        }
      },
      imgCellV: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        '& img': {
          marginBottom: theme.spacing(0.5),
        }
      },
    }
  ),
);

const MonthlySpecialsPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  const [ agentFilter, setAgentFilter ] = useQueryParam('all');
  const [ statusFilter, setStatusFilter ] = useQueryParam('status');
  const state = useSelector((state: RootState) => {
    return {
      agents: state.agents,
      offers: state.allOffers,
    }
  });

  const dispatch = useDispatch();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(actions.loadAllOffers({
      agentId: agentFilter === 'all' ? undefined : agentFilter,
      status: statusFilter ? statusFilter : 'active'
    }));
  }, [ agentFilter, statusFilter ]);


  return (
    <Layout title="Offers" loading={state.offers.loading}>
      <Filters
        agent={agentFilter || 'all'}
        status={statusFilter || 'active'}
        onAgentChange={setAgentFilter}
        onStatusChange={setStatusFilter}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Code</TableCell>
              <TableCell>Agent</TableCell>
              <TableCell align="center">Car</TableCell>
              <TableCell align="center">Model</TableCell>
              <TableCell align="right">Length</TableCell>
              <TableCell align="right">Down</TableCell>
              <TableCell align="right">Mileage</TableCell>
              <TableCell align="right">Monthly</TableCell>
              <TableCell align="right">Created</TableCell>
              <TableCell align="right">User seen</TableCell>
              <TableCell align="right">Expires</TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {state.offers.offers.map(offer => {
              // const time = timeAgo(offer.createTime as number);
              const createTime = distanceInWordsToNow(offer.createTime as number);
              const expireTime = distanceInWordsToNow(offer.expireTime as number);
              const userSeenTime = offer.userSeenTime ? distanceInWordsToNow(offer.userSeenTime as number) : 'No';

              return (
                <TableRow key={offer.id}>
                  <TableCell>
                    {offer.offerCode}
                  </TableCell>
                  <TableCell>
                    {offer.agentData && offer.agentData.name}
                  </TableCell>
                  <TableCell>
                    <div className={classes.imgCellV}>
                      {offer.carData && <CarLogo car={offer.carData} />}
                      {offer.carData && offer.carData.name}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div className={classes.imgCellV}>
                      {offer.modelData && offer.modelData.name}
                      {offer.modelData && <ModelImage model={offer.modelData} />}
                      {offer.trimData && offer.trimData.name}
                    </div>
                  </TableCell>
                  <TableCell align="right">{LeaseLengthLabels.get(offer.leaseLength)}</TableCell>
                  <TableCell align="right">{DownPaymentLabels.get(offer.downPayment)}</TableCell>
                  <TableCell align="right">{LeaseMileageLabels.get(offer.mileage)}</TableCell>
                  <TableCell align="right">${offer.monthlyPayment}</TableCell>
                  <TableCell align="right">{createTime}</TableCell>
                  <TableCell align="right">{userSeenTime}</TableCell>
                  <TableCell align="right">{expireTime}</TableCell>
                  <TableCell align="right">
                    <Button size="small" component={Link} to={`/inquiry/${offer.inquiry}`}>Details</Button>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Layout>
  );
};

export default MonthlySpecialsPage;