import React, { useState, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      formControl: {
        width: '100%',
        marginBottom: theme.spacing(3),
      },
      disabled: {
        opacity: "0.5 !important",
      }
    }
  ),
);

interface Props {
  value: any;
  disabled?: boolean;
  onChange: (value: any) => void;
  labelWidth?: number;
  options: any[] | Map<any, any>;
  filteredKeys?: string[],
  id: string;
  label: string;
  valueField?: string;
  labelField: string;
}

const DropDown: React.FC<Props> = ({ id, label, value, disabled, onChange, labelWidth = 50, options, valueField, labelField, filteredKeys = [] }) => {
  const classes = useStyles();
  const _onChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onChange(event.target.value);
  }

  let list = [];
  if (Array.isArray(options)) {
    list = options;
  }
  else {
    options.forEach((value, key) => {
      if (!filteredKeys.includes(key)) {
        list.push({
          key,
          value
        })
      }
    });
  }

  return (
    <FormControl
      className={clsx(classes.formControl, { [ classes.disabled ]: Boolean(disabled) })}
      variant="outlined"
      disabled={Boolean(disabled)}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        value={value}
        onChange={_onChange}
        labelWidth={labelWidth}
        inputProps={{
          name: id,
          id: id,
        }}
      >
        {list.map((option, index) => (
          <MenuItem key={'option-' + index} value={valueField ? option[ valueField ] : index}>{option[ labelField ]}</MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};


export default DropDown;