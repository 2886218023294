import { useState, useEffect } from 'react';

function useWindowSize() {
  const isClient = typeof window === 'object';

  function getSize() {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined
    };
  }

  const [ windowSize, setWindowSize ] = useState(getSize);

  function handleResize() {
    setWindowSize(getSize());
  }

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (isClient) {
      window.addEventListener('resize', handleResize);
      return () => {
        window.removeEventListener('resize', handleResize)
      };
    }
  }, []);

  return windowSize;
}

export default useWindowSize;