import React, { useEffect, useState, useRef } from "react";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store";
import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Drawer,
  Typography,
  InputAdornment,
  IconButton,
  FormControl,
  OutlinedInput,
  Paper
} from "@material-ui/core";
import SendIcon from '@material-ui/icons/SendOutlined';

import { ChatMessage } from 'leasemojo-common';

import useWindowSize from '../../utils/useWindowSize';

import { actions as chatActions } from '../../services/chat';

import MessageItem from './MessageItem';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        minWidth: 300,
        display: 'flex',
        flexFlow: 'column',
        height: '100vh',
      },

      title: {
        padding: theme.spacing(1, 0),
      },

      messagesContainer: {
        flex: 1,
        overflow: 'auto',
        padding: theme.spacing(2),
      },

      bottom: {
        padding: theme.spacing(2),
      },
      messageInput: {
        width: '100%',
      }
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
}

const Messages: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const windowSize = useWindowSize();
  const dispatch = useDispatch();

  const state = useSelector((state: RootState) => ({
    chat: state.chat,
    user: state.user,
    inquiry: state.inquiryDetails,
  }));

  const { activeOffer } = state.inquiry;


  const [ message, setMessage ] = useState('');
  const onMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(event.target.value);
  };


  const messages: ChatMessage[] = activeOffer && activeOffer.id && state.chat.chats[ activeOffer.id ] ? state.chat.chats[ activeOffer.id ].messages : [];


  const scrollEl = useRef<HTMLDivElement>(null);
  useEffect(() => {
    requestAnimationFrame(() => {
      if (scrollEl && scrollEl.current && open) {
        scrollEl.current.scrollTop = scrollEl.current.scrollHeight;
      }
    });
  }, [ messages.length, open ])

  const activeOfferId = activeOffer && activeOffer.id ? activeOffer.id : null;

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    if (!activeOfferId) {
      return;
    }
    if (open) {
      dispatch(chatActions.loadMessages(activeOfferId));
      dispatch(chatActions.setCurrentChat(activeOfferId));
    }
    else if (!open) {
      dispatch(chatActions.setCurrentChat(null));
      if (activeOffer?.agent === state.user.agent?.id) {
        dispatch(chatActions.resetUnseenMessages(activeOfferId));
      }
    }
  }, [ open, activeOfferId ])

  if (!activeOffer || !activeOffer.id) {
    return null;
  }


  const onSubmitForm = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (activeOffer.id) {
      dispatch(chatActions.sendMessage(activeOffer, message))
      setMessage('');
    }
  };

  const width = windowSize.width ? Math.min(500, windowSize.width / 3) : 300;

  return (
    <Drawer open={open} onClose={onClose} anchor="right" elevation={2}>
      <div className={classes.root} style={{ width }}>
        <Typography variant="h6" align="center" className={classes.title}>Messages</Typography>
        <div className={classes.messagesContainer} ref={scrollEl}>
          {
            messages.map(item => {
              return <MessageItem data={item} key={item.id} />
            })
          }
        </div>
        <Paper className={classes.bottom} elevation={6}>
          <form onSubmit={onSubmitForm}>
            <FormControl variant="outlined" className={classes.messageInput}>
              <OutlinedInput
                placeholder="Send a message"
                labelWidth={0}
                multiline
                rowsMax={6}
                value={message}
                onChange={onMessageChange}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton color="secondary" type="submit" disabled={message.length === 0}>
                      <SendIcon />
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </form>
        </Paper>
      </div>
    </Drawer>
  );
};

export default Messages;