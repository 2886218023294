import React from 'react';
import { RouteComponentProps, Link, Location } from '@reach/router';

import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';

import MenuIcon from '@material-ui/icons/Menu';
import InquiriesIcon from '@material-ui/icons/Commute';
import SalesIcon from '@material-ui/icons/AttachMoney';
import MyOffers from '@material-ui/icons/LocalOffer';
import AgentsIcon from '@material-ui/icons/SupervisedUserCircle';
import BusinessIcon from '@material-ui/icons/Business';
import StarIcon from '@material-ui/icons/Stars';
import IconArrowDown from '@material-ui/icons/KeyboardArrowDown';

import Logo from './Logo';
import { Button, Container, LinearProgress } from '@material-ui/core';
import Helmet from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store';
import { actions as userActions } from '../services/user';

export const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    drawer: {
      [ theme.breakpoints.up('sm') ]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    appBar: {
      marginLeft: drawerWidth,
      [ theme.breakpoints.up('sm') ]: {
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      [ theme.breakpoints.up('sm') ]: {
        display: 'none',
      },
    },
    toolbar: {
      ...theme.mixins.toolbar
    },
    logoContainer: {
      ...theme.mixins.toolbar,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    title: {
      flex: 1,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3, 0),
    },
    navActive: {
      backgroundColor: theme.palette.secondary.main + '',
    },
    progress: {
      position: 'absolute',
      left: 0,
      width: '100%',
      top: 56,
      [ theme.breakpoints.up('sm') ]: {
        top: 64,
      }
    }
  }),
);

interface LayoutProps extends RouteComponentProps {
  title: string;
  className?: string;
  contentSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  loading?: boolean;
}

const Layout: React.FC<LayoutProps> = ({ title, children, contentSize = 'xl', className, loading }) => {
  const classes = useStyles();
  const theme = useTheme();
  const [ mobileOpen, setMobileOpen ] = React.useState(false);
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const dispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  }

  const logout = async () => {
    await dispatch(userActions.signOut());
    handleMenuClose();
  }

  const drawer = (

    <Location>
      {
        ({ location }) => {
          return (
            <div>
              <div className={classes.logoContainer}>
                <Logo variant="normal" />
              </div>
              <Divider />
              <List>
                <ListItem button component={Link} to="/" selected={location.pathname === '/'}>
                  <ListItemIcon><InquiriesIcon color="secondary" /></ListItemIcon>
                  <ListItemText primary="Inquiries" />
                </ListItem>
                <ListItem button component={Link} to="/offers" selected={location.pathname === '/monthly-specials'}>
                  <ListItemIcon><MyOffers color="secondary" /></ListItemIcon>
                  <ListItemText primary="Offers" />
                </ListItem>
                <Divider />
                <ListItem button component={Link} to="/sales" selected={location.pathname === '/sales'}>
                  <ListItemIcon><SalesIcon color="secondary" /></ListItemIcon>
                  <ListItemText primary="Published Sales" />
                </ListItem>
                <ListItem button component={Link} to="/reviews" selected={location.pathname === '/reviews'}>
                  <ListItemIcon><StarIcon color="secondary" /></ListItemIcon>
                  <ListItemText primary="Reviews/Rating" />
                </ListItem>

                {
                  user.agent && user.agent.manager ? (
                    <React.Fragment>
                      <ListItem button component={Link} to="/agents" selected={location.pathname === '/agents'}>
                        <ListItemIcon><AgentsIcon color="secondary" /></ListItemIcon>
                        <ListItemText primary="Agents" />
                      </ListItem>
                      <ListItem button component={Link} to="/company-profile" selected={location.pathname === '/company-profile'}>
                        <ListItemIcon><BusinessIcon color="secondary" /></ListItemIcon>
                        <ListItemText primary="Company Profile" />
                      </ListItem>
                    </React.Fragment>
                  ) : null
                }
              </List>
            </div>
          );
        }
      }
    </Location>
  );

  return (
    <div className={classes.root}>
      <Helmet>
        <title>{title} - {process.env.REACT_APP_SITE_NAME}</title>
      </Helmet>
      <AppBar position="fixed" className={classes.appBar} elevation={loading ? 0 : 1}>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            className={classes.menuButton}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap className={classes.title}>
            {title}
          </Typography>
          <Button color="inherit" onClick={handleMenuOpen} endIcon={<IconArrowDown />}>
            {user.agent && user.agent.name}
          </Button>
          <Menu
            id="profile-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem component={Link} to="/my-profile">My Profile</MenuItem>
            <Divider />
            <MenuItem onClick={logout}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <nav className={classes.drawer} aria-label="mailbox folders">
        <Hidden smUp implementation="css">
          <Drawer
            variant="temporary"
            anchor={theme.direction === 'rtl' ? 'right' : 'left'}
            open={mobileOpen}
            onClose={handleDrawerToggle}
            classes={{
              paper: classes.drawerPaper,
            }}
            ModalProps={{
              keepMounted: true, // Better open performance on mobile.
            }}
          >
            {drawer}
          </Drawer>
        </Hidden>
        <Hidden xsDown implementation="css">
          <Drawer
            classes={{
              paper: classes.drawerPaper,
            }}
            variant="permanent"
            open
          >
            {drawer}
          </Drawer>
        </Hidden>
      </nav>
      <main className={classes.content}>
        <div className={classes.toolbar} />
        {
          loading ? <LinearProgress className={classes.progress} color="secondary" /> : null
        }
        <Container maxWidth={contentSize} className={className}>
          {children}
        </Container>
      </main>
    </div>
  );
}

export default Layout;