import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  Card,
  CardHeader,
  CardMedia,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Grid,
  IconButton,
} from "@material-ui/core";

import IconEdit from '@material-ui/icons/Edit';
import IconDelete from '@material-ui/icons/Delete';
import IconPhoto from '@material-ui/icons/Photo';

import {
  SoldListingItem,
  timeAgo,
  getExteriorColorIndex,
  getInteriorColorIndex,
  LeaseLengthLabels,
  CreditScoreLabels,
  DownPaymentLabels
} from "leasemojo-common";
import { useDialog } from "../../components/DialogProvider";
import { useQueryParams, StringParam, BooleanParam } from "use-query-params";
import clsx from "clsx";
import { useDispatch } from "react-redux";
import { actions } from '../../services/sales';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        maxWidth: 400,
        width: '100%',
        margin: theme.spacing(2),
      },
      media: {
        height: 0,
        paddingTop: '48%',
        backgroundSize: 'contain',
        position: 'relative',
      },
      mediaCover: {
        paddingTop: '56%',
        backgroundSize: 'cover',
      },
      actions: {
        justifyContent: 'center',
      },
      numPhotosBadge: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        borderRadius: 20,
        padding: theme.spacing(1, 2),
        color: '#fff',
        display: 'flex',
        alignItems: 'center',
      },
      photoIcon: {
        fontSize: 15,
        marginRight: theme.spacing(1),
      }

    }
  ),
);

interface Props {
  data: SoldListingItem;
}

const SaleItemCard: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const dialog = useDialog();
  const dispatch = useDispatch();

  const [ _, setDialog ] = useQueryParams({
    dialog: BooleanParam,
    listingId: StringParam
  });

  const exteriorColorIndex = getExteriorColorIndex(data.exteriorColor, data.trimData.exteriorColors);
  const interiorColorIndex = getInteriorColorIndex(data.interiorColor, data.trimData.interiorColors);


  const deleteItem = () => {
    dialog.confirm({
      title: 'Please confirm',
      subtitle: 'You want to delete this listing?',
      onConfirm: () => {
        dispatch(actions.remove(data.id));
      }
    });
  }

  const editItem = () => {
    setDialog({
      dialog: true,
      listingId: data.id
    })
  }

  const getImgUrl = () => {
    if (data.photos.length === 0) {
      const imgUrl = data.trimData.exteriorColors[ exteriorColorIndex ].images.md ? data.trimData.exteriorColors[ exteriorColorIndex ].images.md : data.modelData?.placeholderImage;
      return process.env.REACT_APP_CDN_BASE_CARS + '/' + imgUrl || '';
    }
    else {
      return data.photos[ 0 ].src ? process.env.REACT_APP_CDN_BASE_MAIN + '/' + data.photos[ 0 ].src : data.photos[ 0 ].original;
    }
  }

  const title = `${data.modelData?.modelYear} ${data.carData?.name} ${data.modelData?.name}`;

  return (
    <Card className={classes.root}>
      <CardHeader
        title={title}
        subheader={`${data.agentData?.name} - ${timeAgo(data.createTime as number)}`}
        action={data.status === 'pending' ? <Typography color="secondary">pending</Typography> : null}
      />
      <CardMedia
        className={clsx(classes.media, { [ classes.mediaCover ]: data.photos.length > 0 })}
        image={getImgUrl()}
        title={title}
      >
        <div className={classes.numPhotosBadge}>
          <IconPhoto className={classes.photoIcon} />
          <Typography variant="body2">{data.photos.length}</Typography>
        </div>
      </CardMedia>
      <CardContent>
        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Typography variant="caption">Trim</Typography>
            <Typography variant="body1">{data.trimData.name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Exterior</Typography>
            <Typography variant="body1">{data.trimData.exteriorColors[ exteriorColorIndex ].name}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Interior</Typography>
            <Typography variant="body1">{data.trimData.interiorColors[ interiorColorIndex ].name}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Length</Typography>
            <Typography variant="body1">{LeaseLengthLabels.get(data.leaseLength)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Credit Score</Typography>
            <Typography variant="body1">{CreditScoreLabels.get(data.creditScore)}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Down</Typography>
            <Typography variant="body1">{DownPaymentLabels.get(data.downPayment)}</Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography variant="caption">Monthly</Typography>
            <Typography variant="body1">${data.monthlyPayment}</Typography>
          </Grid>

        </Grid>
      </CardContent>
      <CardActions className={classes.actions}>
        <IconButton onClick={editItem}>
          <IconEdit />
        </IconButton>

        <IconButton onClick={deleteItem}>
          <IconDelete />
        </IconButton>

      </CardActions>
    </Card>
  );
};

export default SaleItemCard;