import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import { CarMake } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      logo: {
        width: 50,
        height: 50,
      },
    }
  ),
);

interface CarLogoProps {
  car: CarMake
}

const CarLogo: React.FC<CarLogoProps> = ({ car }) => {
  const classes = useStyles();
  return (
    <img src={`${process.env.REACT_APP_CDN_BASE_CARS}/${car.logoUrl}`} className={classes.logo} alt={car.name} />
  );
};

export default CarLogo;