import React from "react";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, List, ListItem, ListItemText, Typography } from "@material-ui/core";
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';

import { useSelector } from "react-redux";
import { RootState } from "../../store";

import {
  getExteriorColorIndex,
  getInteriorColorIndex,
  prepandDefaultExteriorColor,
  prepandDefaultInteriorColor,
  ModelTrim,
} from 'leasemojo-common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      card: {
        width: '100%',
        maxWidth: 400,
        [ theme.breakpoints.down('sm') ]: {
          maxWidth: '100%',
        }
      },
      cardContent: {
        padding: '0px !important',
      },
      media: {
        height: 0,
        paddingTop: '45%', // 16:9
        backgroundSize: 'contain',
      },
      title: {
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 300,
        overflow: 'hidden',
      },
    }
  ),
);

const InquiryCard: React.FC = () => {
  const classes = useStyles();

  const state = useSelector((state: RootState) => state.inquiryDetails)


  if (!state.inquiry || !state.inquiry.carData || !state.inquiry.modelData || !state.inquiry.trimData) {
    return null;
  }

  const trim = Object.assign({}, state.inquiry.trimData) as ModelTrim;

  trim.exteriorColors = prepandDefaultExteriorColor(trim.exteriorColors, trim.defaultColors.exteriorColorId);
  trim.interiorColors = prepandDefaultInteriorColor(trim.interiorColors, trim.defaultColors.interiorColorId);
  
  const exteriorColorIndex = getExteriorColorIndex(state.inquiry.exteriorColor, trim.exteriorColors);
  const interiorColorIndex = getInteriorColorIndex(state.inquiry.interiorColor, trim.interiorColors);

  const imgUrl = trim.exteriorColors[ exteriorColorIndex ].images.md ? trim.exteriorColors[ exteriorColorIndex ].images.md : state.inquiry.modelData.placeholderImage;

  let priceNum = trim.msrp + trim.exteriorColors[ exteriorColorIndex ].msrp + trim.interiorColors[ interiorColorIndex ].msrp;
  let price = priceNum.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

  return (
    <Card className={classes.card}>
      <CardHeader
        title={`${state.inquiry.modelData.modelYear} ${state.inquiry.carData.name} ${state.inquiry.modelData.name}`}
        subheader={trim.name}
        classes={{
          subheader: classes.title,
          title: classes.title,
        }}
      />
      <CardMedia
        className={classes.media}
        image={process.env.REACT_APP_CDN_BASE_CARS + "/" + imgUrl}
        title={state.inquiry.carData.name}
      />
      <CardContent className={classes.cardContent}>
        <List >
          <ListItem>
            <ListItemText primary={trim.exteriorColors[ exteriorColorIndex ].name} secondary="Exterior color" />
          </ListItem>
          <ListItem>
            <ListItemText primary={trim.interiorColors[ interiorColorIndex ].name} secondary="Interior color" />
          </ListItem>
          <ListItem>
            <ListItemText primary={`$${price}`} secondary="MSRP" />
          </ListItem>
        </List>
      </CardContent>
    </Card>
  );
};

export default InquiryCard;