import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import firebase, { db } from '../firebase';
import { AppThunk } from '../store';

import orderBy from 'lodash/orderBy';

import { actions as notifications } from './notifications';

import {
  firestoreLookup,
  CarMake,
  CarModel,
  fixTimeStamps,
  UserProfilePublic,
} from 'leasemojo-common';


import {
  InquiryData,
  Dealer,
  ModelTrim,
} from 'leasemojo-common';

interface InquiriesState {
  inquiries: InquiryData[],
  loading: boolean,
  loadingDetails: boolean;
  submitOfferPending: boolean;
}

const initialState: InquiriesState = {
  inquiries: [],
  loading: true,
  loadingDetails: false,
  submitOfferPending: false,
};


interface ProcessInquiryResult {
  inquiry: InquiryData;
  type: firebase.firestore.DocumentChangeType;
  oldIndex: number;
  index: number;
}


const inquiriesService = createSlice({
  name: 'inquiries',
  initialState,
  reducers: {
    setLoading(state, action: PayloadAction<boolean>): InquiriesState {
      return { ...state, loading: action.payload }
    },
    inquiryAdded(state, action: PayloadAction<InquiryData>): InquiriesState {
      const newList = orderBy([
        action.payload,
        ...state.inquiries,
      ], 'createTime', 'desc');

      return {
        ...state,
        inquiries: newList,
      }
    },
    inquiryUpdated(state, action: PayloadAction<ProcessInquiryResult>): InquiriesState {
      const newList = [
        ...state.inquiries
      ];

      newList[ action.payload.index ] = action.payload.inquiry;

      return {
        ...state,
        inquiries: newList,
      }
    },
    inquiryRemoved(state, action: PayloadAction<string>): InquiriesState {
      const newList = state.inquiries.filter(item => item.id !== action.payload);

      return {
        ...state,
        inquiries: newList,
      }
    },
  }
});

const loadInquiries = (): AppThunk => async (dispatch, getState) => {
  db.collection('inquiries')
    .where('status', '==', 'active')
    .orderBy('createTime', 'desc').onSnapshot({
      next: async (snapshot) => {
        if (getState().inquiries.submitOfferPending) {
          return;
        }

        const state = getState();
        const isInitial = state.inquiries.loading;
        if (isInitial) {
          dispatch(inquiriesService.actions.setLoading(false));
        }

        const result = await processInquiries(snapshot, getState().inquiries.inquiries);

        result.forEach(item => {
          if (item.type === 'added') {
            dispatch(inquiriesService.actions.inquiryAdded(item.inquiry));
            if (!isInitial) {
              dispatch(notifications.show({ message: 'New inquiry', targetUrl: `/inquiry/${item.inquiry.id}` }));
            }
          }
          else if (item.type === 'modified') {
            dispatch(inquiriesService.actions.inquiryUpdated(item));
          }
          else if (item.type === 'removed' && item.inquiry.id) {
            dispatch(inquiriesService.actions.inquiryRemoved(item.inquiry.id));
            if (!isInitial) {
              dispatch(notifications.show({ message: 'User removed the inquiry' }));
            }
          }
        });
      },
      error: (err) => {
        console.log('loadInquiries', err);
      }
    });
}

const processInquiries = async (snapshot: firebase.firestore.QuerySnapshot, currentInquiries: InquiryData[]): Promise<ProcessInquiryResult[]> => {
  const userRefs: firebase.firestore.DocumentReference[] = [];
  const carRefs: firebase.firestore.DocumentReference[] = [];
  const modelRefs: firebase.firestore.DocumentReference[] = [];
  const trimRefs: firebase.firestore.DocumentReference[] = [];
  const dealerRefs: firebase.firestore.DocumentReference[] = [];

  const inquiries: ProcessInquiryResult[] = [];

  snapshot.docChanges().forEach(item => {
    const data = fixTimeStamps(item.doc.data()) as InquiryData;
    data.id = item.doc.id;

    const userRef = db.collection('users').doc(data.user).collection('public').doc('profile');
    const carRef = db.collection('cars').doc(data.car);
    const modelRef = carRef.collection('models').doc(data.model);
    const trimRef = modelRef.collection('trims').doc(data.trim);

    userRefs.push(userRef);
    carRefs.push(carRef);
    modelRefs.push(modelRef);
    trimRefs.push(trimRef);


    for (let dealerId in data.offers) {
      dealerRefs.push(db.collection('dealers').doc(dealerId));
      trimRefs.push(modelRef.collection('trims').doc(data.offers[ dealerId ].trim));
    }

    for (let key in data.offers) {
      data.offers[ key ] = fixTimeStamps(data.offers[ key ]);
    }

    inquiries.push({
      inquiry: data,
      type: item.type,
      oldIndex: item.oldIndex,
      index: item.newIndex,
    });
  });

  const cars = firestoreLookup<CarMake>(carRefs, { cache: true });
  const models = firestoreLookup<CarModel>(modelRefs, { cache: true });
  const trims = firestoreLookup<ModelTrim>(trimRefs, { cache: true });
  const dealers = firestoreLookup<Dealer>(dealerRefs, { cache: true, cacheExpire: '6h' });
  const users = firestoreLookup<UserProfilePublic>(userRefs, { cache: true, cacheExpire: '6h', idField: 'id' });

  const inquiryData = await Promise.all([ cars, models, trims, dealers, users ]);

  return inquiries.map(item => {
    item.inquiry.carData = inquiryData[ 0 ][ item.inquiry.car ];
    item.inquiry.modelData = inquiryData[ 1 ][ item.inquiry.model ];
    item.inquiry.trimData = inquiryData[ 2 ][ item.inquiry.trim ];
    item.inquiry.userInfo = inquiryData[ 4 ][ item.inquiry.user ];

    for (let dealerId in item.inquiry.offers) {
      item.inquiry.offers[ dealerId ].trimData = inquiryData[ 2 ][ item.inquiry.offers[ dealerId ].trim ];
      item.inquiry.offers[ dealerId ].dealerData = fixTimeStamps(inquiryData[ 3 ][ item.inquiry.offers[ dealerId ].dealer ]);
    }
    return item;
  });
}

export const actions = {
  loadInquiries
};
export default inquiriesService.reducer;