import { db } from '../firebase';
import { firestoreQuery, CarMake, CarModel, ModelTrim } from 'leasemojo-common';


export const getCars = async () => {
  try {
    const result = await firestoreQuery<CarMake>(db.collection('cars').orderBy('name', 'asc'), { cache: true });
    return result;
  }
  catch (e) {
    console.error(e);
    return [];
  }
}

export const getModels = async (carId: string) => {
  try {
    const query = db.collection('cars').doc(carId).collection('models').orderBy('name', 'asc');
    const result = await firestoreQuery<CarModel>(query, { cache: true });
    return result;
  }
  catch (e) {
    console.error(e);
    return [];
  }
}

export const getTrims = async (carId: string, modelId: string) => {
  try {
    const query = db.collection('cars').doc(carId).collection('models').doc(modelId).collection('trims').orderBy('name', 'asc');
    const result = await firestoreQuery<ModelTrim>(query, { cache: true });
    return result;
  }
  catch (e) {
    console.error(e);
    return [];
  }
}
