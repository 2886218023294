import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, useTheme, Grid, TextField, FormControl, InputLabel, OutlinedInput, InputAdornment, Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import find from 'lodash/find';

import DropDown from './DropDown';
import PhotoItem from './PhotoList/PhotoItem';
import PhotoAddButton from './PhotoList/AddButton';

import {
  LeaseOffer,
  LeaseLength,
  DownPayment,
  LeaseMileage,
  LeaseLengthLabels,
  LeaseMileageLabels,
  DownPaymentLabels,
  CreditScoreLabels,
  CreditScore
} from "leasemojo-common";
import ProgressButtton from "../../components/ProgressButton";

import { actions } from '../../services/publishSale';
import { RootState } from "../../store";
import { AddPublishedSaleArgs, PhotoSource } from "../../typings/types";
import { useQueryParam, StringParam } from "use-query-params";


function usePrevious(value: any) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[ 500 ],
      },
      title: {
        padding: theme.spacing(2),
      },
      content: {

      },
      formControl: {
        width: '100%',
        marginBottom: theme.spacing(3),
      },
      bottom: {
        borderTop: '1px solid #eee',
        textAlign: 'center',
        marginTop: theme.spacing(4),
        paddingTop: theme.spacing(2),
        width: '100%',
      },
      photoList: {
        display: 'flex',
        overflowX: 'scroll',
        padding: theme.spacing(1, 2, 2, 0.5),
        alignItems: 'center',
      },
      photoListTitle: {
        paddingLeft: theme.spacing(0.5),
      }
    }
  ),
);

interface Props {
  open: boolean;
  onClose: () => void;
}

const AddSaleModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.publishSale);

  const [ offer ] = useQueryParam('offer', StringParam);
  const [ listingId ] = useQueryParam('listingId', StringParam);

  const formState = state.form;

  const [ photos = [], setPhotos ] = useState<File[]>();


  useEffect(() => {
    if (open) {
      setPhotos([]);
      if (listingId) {
        dispatch(actions.loadFromId(listingId));
      }
      else if (offer) {
        dispatch(actions.loadFromOffer(offer));
      }
      else {
        dispatch(actions.resetForm());
        dispatch(actions.loadCars());
      }
    }
  }, [ open ])


  const setCar = (car: string) => {
    dispatch(actions.setForm({ ...formState, car, model: undefined, trim: undefined, interiorColor: undefined, exteriorColor: undefined, }))
    dispatch(actions.loadModels(car));
  }

  const setModel = (model: string) => {
    dispatch(actions.setForm({ ...formState, model, trim: undefined, interiorColor: undefined, exteriorColor: undefined, }));
    if (formState.car) {
      dispatch(actions.loadTrims(formState.car, model));
    }
  }

  const setTrim = (trim: string) => {
    dispatch(actions.setForm({ ...formState, trim, interiorColor: undefined, exteriorColor: undefined, }));
  }

  const onSelectChange = (name: string) => (value: any) => {
    dispatch(actions.setForm({ ...formState, [ name ]: value }));
  };
  const onTextChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(actions.setForm({ ...formState, [ name ]: event.target.value }));
  };

  const photoOnAdd = (file: File) => {
    setPhotos([
      ...photos,
      file
    ]);
  }

  const photoOnRemove = (index: number, type: 'local' | 'remote') => {
    console.log('photoOnRemove', index, type);
    if (type === 'local') {
      setPhotos(photos.filter((item, i) => index !== i));
    }
    else {
      dispatch(actions.removePhoto(index));
    }
  }

  const trimData = typeof formState.trim !== 'undefined' ? find(state.trims, { id: formState.trim }) : null;
  const exteriorColors = trimData ? trimData.exteriorColors : [];
  const interiorColors = trimData ? trimData.interiorColors : [];

  const onSubmitForm = async (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    await dispatch(actions.save(photos));
    onClose();
  }

  const canSave = () => {
    return typeof formState.car !== 'undefined'
      && typeof formState.model !== 'undefined'
      && typeof formState.trim !== 'undefined'
      && typeof formState.exteriorColor !== 'undefined'
      && typeof formState.interiorColor !== 'undefined'
      && typeof formState.leaseLength !== 'undefined'
      && typeof formState.downPayment !== 'undefined'
      && typeof formState.mileage !== 'undefined'
      && typeof formState.creditScore !== 'undefined'
      && typeof formState.monthlyPayment !== 'undefined'
      && typeof formState.zip !== 'undefined'
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.root}
      maxWidth="sm"
      fullWidth
      fullScreen={fullScreen}
    >
      <DialogTitle className={classes.title}>
        {listingId ? 'Edit Sold Listing' : 'New Sold Listing'}
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers className={classes.content}>
        <form onSubmit={onSubmitForm} noValidate>

          <DropDown
            id="car"
            label="Car"
            value={formState.car ?? ''}
            onChange={setCar}
            options={state.cars}
            disabled={state.loading || state.cars.length === 0 || state.savePending}
            labelField="name"
            valueField="id"
          />

          <DropDown
            id="model"
            label="Model"
            value={formState.model ?? ''}
            onChange={setModel}
            options={state.models}
            disabled={state.loading || state.models.length === 0 || state.savePending}
            labelField="name"
            valueField="id"
          />

          <DropDown
            id="trim"
            label="Trim"
            value={formState.trim ?? ''}
            onChange={setTrim}
            options={state.trims}
            disabled={state.loading || state.trims.length === 0 || state.savePending}
            labelField="name"
            valueField="id"
          />

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DropDown
                id="exterior"
                label="Exterior"
                value={formState.exteriorColor ?? ''}
                onChange={onSelectChange('exteriorColor')}
                options={exteriorColors}
                disabled={state.loading || !trimData || state.savePending}
                labelField="name"
                valueField="id"
              />
            </Grid>
            <Grid item xs={6}>
              <DropDown
                id="interior"
                label="Interior"
                value={formState.interiorColor ?? ''}
                onChange={onSelectChange('interiorColor')}
                options={interiorColors}
                disabled={state.loading || !trimData || state.savePending}
                labelField="name"
                valueField="id"
              />
            </Grid>
          </Grid>


          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DropDown
                id="length"
                label="Lease Length"
                value={formState.leaseLength ?? ''}
                onChange={onSelectChange('leaseLength')}
                options={LeaseLengthLabels}
                disabled={state.loading || state.savePending}
                labelField="value"
                valueField="key"
                filteredKeys={[ 'not_sure' ]}
                labelWidth={100}
              />
            </Grid>
            <Grid item xs={6}>
              <DropDown
                id="mileage"
                label="Mileage"
                value={formState.mileage ?? ''}
                onChange={onSelectChange('mileage')}
                options={LeaseMileageLabels}
                disabled={state.loading || state.savePending}
                labelField="value"
                valueField="key"
                filteredKeys={[ 'not_sure' ]}
                labelWidth={60}
              />
            </Grid>
          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DropDown
                id="credit_score"
                label="Customer Credit Score"
                value={formState.creditScore ?? ''}
                onChange={onSelectChange('creditScore')}
                options={CreditScoreLabels}
                disabled={state.loading || state.savePending}
                labelField="value"
                valueField="key"
                labelWidth={170}
                filteredKeys={[ 'not_sure' ]}
              />
            </Grid>
            <Grid item xs={6}>
              <DropDown
                id="down"
                label="Down"
                value={formState.downPayment ?? ''}
                onChange={onSelectChange('downPayment')}
                options={DownPaymentLabels}
                disabled={state.loading || state.savePending}
                labelField="value"
                valueField="key"
                filteredKeys={[ 'not_sure' ]}
              />
            </Grid>

          </Grid>

          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormControl className={classes.formControl} variant="outlined" disabled={state.loading || state.savePending}>
                <InputLabel htmlFor="zip">Zip Code</InputLabel>
                <OutlinedInput
                  value={formState.zip ?? ''}
                  onChange={onTextChange('zip')}
                  id="zip"
                  labelWidth={125}
                />
              </FormControl>
            </Grid>

            <Grid item xs={6}>
              <FormControl className={classes.formControl} variant="outlined" disabled={state.loading || state.savePending}>
                <InputLabel htmlFor="monthly-payment">Monthly Payment</InputLabel>
                <OutlinedInput
                  value={formState.monthlyPayment ?? ''}
                  onChange={onTextChange('monthlyPayment')}
                  id="monthly-payment"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                  labelWidth={125}
                />
              </FormControl>
            </Grid>

          </Grid>

          <div>
            <Typography variant="body2" className={classes.photoListTitle}>Add Photos</Typography>
            <div className={classes.photoList}>
              {
                formState.photos && formState.photos.map((photo, index) => (
                  <PhotoItem
                    src={photo}
                    key={'photo-' + index}
                    onRemove={photoOnRemove}
                    index={index}
                    loading={state.savePending}
                  />
                ))
              }
              {
                photos.map((photo, index) => (
                  <PhotoItem
                    src={photo}
                    key={'photo-' + index}
                    onRemove={photoOnRemove}
                    index={index}
                    loading={typeof state.photosLoadingProgress[ index ] !== 'undefined'}
                    progress={state.photosLoadingProgress[ index ]} />
                ))
              }

              {
                photos.length < 4 ? (
                  <PhotoAddButton onAdd={photoOnAdd} />
                ) : null
              }

            </div>
          </div>

          <div className={classes.bottom}>
            <ProgressButtton type="submit" variant="contained" color="primary" loading={state.loading} disabled={state.loading || !canSave()}>
              Save
            </ProgressButtton>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AddSaleModal;