import React from "react";
import clsx from 'clsx';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";

import IconStar from '@material-ui/icons/StarBorder';
import IconStarFull from '@material-ui/icons/Star';
import ReactRating from 'react-rating';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      icon: {
        color: '#ffc107',

        '&.large': {
          fontSize: 35,
        }
      },
    }
  ),
);

interface Props {
  value: number;
  readonly?: boolean;
  precise?: boolean;
  onChange?: (value: number) => void;
  large?: boolean;
}

const Rating: React.FC<Props> = ({ value, readonly, precise, onChange, large }) => {
  const classes = useStyles();
  return (
    <ReactRating
      initialRating={value}
      fractions={precise ? 2 : 1}
      readonly={readonly}
      emptySymbol={<IconStar className={clsx(classes.icon, {'large': large})} />}
      fullSymbol={<IconStarFull className={clsx(classes.icon, {'large': large})} />}
      onChange={onChange}
    />
  );
};

export default Rating;