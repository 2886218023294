import { analytics } from './firebase';



export const trackScreenView = (screenName: string) => {
  trackEvent('screen_view', {
    screen_name: screenName
  });
}

export const trackPageView = () => {
  trackEvent('page_view');
}

export const trackEvent = (eventName: string, params?: { [ key: string ]: any }) => {
  analytics.logEvent(eventName, params);
}