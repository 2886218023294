import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import { Link as MuiLink, Paper } from '@material-ui/core';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { Link } from '@reach/router';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Logo from '../components/Logo';
import { RouteComponentProps, navigate } from '@reach/router';

import ProgressButton from '../components/ProgressButton';
import { useDispatch, useSelector } from 'react-redux';
import { actions as userActions } from '../services/user';
import { RootState } from '../store';


const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      maxWidth: 450,
      marginTop: theme.spacing(8),
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: theme.spacing(2, 4),
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    logo: {
      // marginBottom: theme.spacing(2),
    }
  }),
);

const SignIn: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();

  const [ error, setError ] = useState(false);
  const [ email, setEmail ] = useState('');
  const [ password, setPassword ] = useState('');

  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.user);

  const login = async () => {
    const result = await dispatch(userActions.signIn(email, password));
    if (!result) {
      setError(true);
    }
    else {
      navigate('/');
    }
  };

  const onSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (email && password) {
      login();
    }

    return;
  }

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }

  return (
    <Container component="main" maxWidth="sm" >
      <Paper className={classes.paper} elevation={1}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Logo variant="normal" className={classes.logo} />
        <Typography paragraph>Dealers</Typography>
        {
          error ? <Typography color="error">Invalid Email/Password</Typography> : null
        }
        <form className={classes.form} noValidate onSubmit={onSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            onChange={onEmailChange}
            value={email}
            disabled={state.loginInProgress}
            autoFocus
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            onChange={onPasswordChange}
            value={password}
            disabled={state.loginInProgress}
          />
          {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
          <ProgressButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            loading={state.loginInProgress}
          >
            Sign In
          </ProgressButton>

          <MuiLink href="#" variant="body2" component={Link} to="/forgot-password">
            Forgot password?
          </MuiLink>
        </form>
      </Paper>
    </Container>
  );
}

export default SignIn;