import React from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, Typography, Divider, Button, Box, Badge } from "@material-ui/core";
import {
  CreditScoreLabels,
  DownPaymentLabels,
  LeaseLengthLabels,
  LeaseMileageLabels,
  timeAgo,
} from 'leasemojo-common';
import { useSelector } from "react-redux";
import { RootState } from "../../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        padding: theme.spacing(2),
        width: '100%',
        maxWidth: 350,
        marginRight: theme.spacing(2),
        [ theme.breakpoints.down('sm') ]: {
          maxWidth: '100%',
          marginRight: 0,
          marginBottom: theme.spacing(2),
        }
      },
      divider: {
        marginBottom: theme.spacing(3),
      },
      bottom: {
        display: 'flex',
        justifyContent: 'center',
      }
    }
  ),
);

interface Props {
  onMessagesClick: () => void;
  onSendOfferClick: () => void;
}

const CustomerInfo: React.FC<Props> = ({ onSendOfferClick, onMessagesClick }) => {
  const classes = useStyles();
  
  const state = useSelector((state: RootState) => state.inquiryDetails)
  const agent = useSelector((state: RootState) => state.user.agent);

  if (!agent || !state.inquiry) {
    return null;
  }

  const sendOfferDisabled = Boolean(state.inquiry.offers[agent.dealer]);
  const canSendMessage = state.activeOffer && state.activeOffer.agent === agent.id && state.activeOffer.chatAllowed;

  return (
    <Paper className={classes.root}>
      <Typography variant="h6">Customer Info</Typography>
      <Divider className={classes.divider} />

      <Typography variant="caption">Inquiry time</Typography>
      <Typography paragraph>{timeAgo(state.inquiry.createTime as number)}</Typography>

      <Typography variant="caption">Zip Code</Typography>
      <Typography paragraph>{state.inquiry.zip}</Typography>

      <Typography variant="caption">Credit Score</Typography>
      <Typography paragraph>{CreditScoreLabels.get(state.inquiry.creditScore)}</Typography>

      <Typography variant="caption">Lease Length</Typography>
      <Typography paragraph>{LeaseLengthLabels.get(state.inquiry.leaseLength)}</Typography>

      <Typography variant="caption">Down Payment</Typography>
      <Typography paragraph>{DownPaymentLabels.get(state.inquiry.downPayment)}</Typography>

      <Typography variant="caption">Mileage</Typography>
      <Typography paragraph>{LeaseMileageLabels.get(state.inquiry.mileage)}</Typography>


      <div className={classes.bottom}>
        <Box marginRight={1}>
          <Button color="primary" variant="contained" onClick={onSendOfferClick} disabled={sendOfferDisabled}>Send Offer</Button>
        </Box>
        <Badge badgeContent={state.activeOffer && state.activeOffer.unseenMessagesAgent} color="secondary">
          <Button
            color="primary"
            variant="contained"
            onClick={onMessagesClick}
            disabled={!canSendMessage}>
            Messages
        </Button>
        </Badge>
      </div>
    </Paper>
  );
};

export default CustomerInfo;