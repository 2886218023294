import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import orderBy from 'lodash/orderBy';
import { AppThunk } from '../store';
import { DealerAgent } from 'leasemojo-common';
import firebase, { db } from '../firebase';

import find from 'lodash/find';

import { actions as notifications } from './notifications';


interface AgentsState {
  agents: DealerAgent[];
  loadingList: boolean;
  loadingOther: boolean;
  deleteInProgress: string[];
}

const initialState: AgentsState = {
  agents: [],
  loadingList: false,
  loadingOther: false,
  deleteInProgress: [],
};


const agentsService = createSlice({
  name: 'agents',
  initialState,
  reducers: {
    setLoadingList(state, action: PayloadAction<boolean>): AgentsState {
      return { ...state, loadingList: action.payload, };
    },
    setLoadingOther(state, action: PayloadAction<boolean>): AgentsState {
      return { ...state, loadingOther: action.payload, };
    },
    setDeleteInProgress(state, action: PayloadAction<string>): AgentsState {
      return {
        ...state, deleteInProgress: [
          ...state.deleteInProgress,
          action.payload,
        ],
      };
    },
    agentAdded(state, action: PayloadAction<DealerAgent>): AgentsState {
      const newList = orderBy([
        action.payload,
        ...state.agents,
      ], 'createTime', 'desc');

      return {
        ...state,
        agents: newList
      }
    },
    agentUpdated(state, action: PayloadAction<DealerAgent>): AgentsState {
      const newList = state.agents.map(agent => {
        if (agent.id === action.payload.id) {
          return action.payload;
        }
        return { ...agent };
      });

      return {
        ...state,
        agents: newList
      }
    },
    agentRemoved(state, action: PayloadAction<string>): AgentsState {
      const newList = state.agents.filter(agent => agent.id !== action.payload);

      return {
        ...state,
        agents: newList
      }
    }
  }
});


const loadList = (): AppThunk => async (dispatch, getState) => {
  const dealer = getState().user.dealer;
  if (!dealer) {
    return;
  }

  dispatch(agentsService.actions.setLoadingList(true));
  db.collection('dealers').doc(dealer.id)
    .collection('agents')
    .where('status', '==', 'active')
    .orderBy('createTime', 'desc')
    .onSnapshot({
      next: (snapshot) => {
        snapshot.docChanges().forEach(item => {
          const agent = item.doc.data() as DealerAgent;
          agent.id = item.doc.id;
          agent.createTime = agent.createTime ? (agent.createTime as firebase.firestore.Timestamp).seconds * 1000 : new Date().getTime();
          agent.updateTime = agent.updateTime ? (agent.updateTime as firebase.firestore.Timestamp).seconds * 1000 : null;
          if (item.type === 'added') {
            const existing = find(getState().agents.agents, { id: item.doc.id });
            if (existing) {
              dispatch(agentsService.actions.agentUpdated(agent));
            }
            else {
              dispatch(agentsService.actions.agentAdded(agent));
            }

          }
          else if (item.type === 'modified') {
            dispatch(agentsService.actions.agentUpdated(agent));
          }
          else if (item.type === 'removed') {
            dispatch(agentsService.actions.agentRemoved(agent.id));
          }
        });

        if (getState().agents.loadingList) {
          dispatch(agentsService.actions.setLoadingList(false));
        }
      },
      error: (e) => {
        console.error(e);
      }
    });
};

interface AddAgentArgs {
  name: string,
  phone: string,
  email: string,
  password: string
};

const addAgent = (data: AddAgentArgs): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(agentsService.actions.setLoadingOther(true));

    const dealer = getState().user.dealer;
    if (!dealer) {
      return;
    }

    const createAgentFunc = firebase.functions().httpsCallable('createAgent');

    await createAgentFunc(data);
    return true;
  }
  catch (e) {
    console.error(e);
    dispatch(notifications.show({ message: e.message, variant: 'error' }));
    return false;
  }
  finally {
    dispatch(agentsService.actions.setLoadingOther(false));
  }
};

interface UpdateAgentArgs extends Partial<AddAgentArgs> {
  agentId: string;
}

const updateAgent = (data: UpdateAgentArgs): AppThunk => async (dispatch, getState) => {
  dispatch(agentsService.actions.setLoadingOther(true));

  try {
    const dealer = getState().user.dealer;
    if (!dealer) {
      return;
    }
    const updateAgentFunc = firebase.functions().httpsCallable('updateAgent');

    await updateAgentFunc(data);
    return true;
  }
  catch (e) {
    console.error(e);
    dispatch(notifications.show({ message: e.message, variant: 'error' }));
    return false;
  }
  finally {
    dispatch(agentsService.actions.setLoadingOther(false));
  }
};


const deleteAgent = (agentId: string): AppThunk => async (dispatch, getState) => {
  dispatch(agentsService.actions.setDeleteInProgress(agentId));

  try {
    const dealer = getState().user.dealer;
    if (!dealer) {
      return;
    }
    const deleteAgentFunc = firebase.functions().httpsCallable('deleteAgent');

    await deleteAgentFunc({ agentId });
    return true;
  }
  catch (e) {
    console.error(e);
    return false;
  }
};





export const actions = {
  loadList,
  addAgent,
  updateAgent,
  deleteAgent,
};

export default agentsService.reducer;