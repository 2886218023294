import React from "react";
import { useSelector } from 'react-redux';
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme } from "@material-ui/core";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';

import { RootState } from '../../store';

import { OfferStatus } from 'leasemojo-common';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      filterItem: {
        minWidth: 100,
        marginRight: theme.spacing(2),
      },
      root: {
        marginBottom: theme.spacing(3),
      }
    }
  ),
);

interface Args {
  agent: string;
  status: OfferStatus;
  onAgentChange: (agent: string) => void;
  onStatusChange: (status: string) => void;
}

const OffersFilters: React.FC<Args> = ({ agent, status, onAgentChange, onStatusChange }) => {
  const classes = useStyles();
  const agents = useSelector((state: RootState) => state.agents.agents);

  const handleAgentFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onAgentChange(event.target.value as string);
  };
  const handleStatusFilterChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    onStatusChange(event.target.value as string);
  };

  return (
    <Box display="flex" justifyContent="flex-end" className={classes.root}>
      <FormControl className={classes.filterItem}>
        <InputLabel id="status-filter-label">Status</InputLabel>
        <Select
          labelId="status-filter-label"
          value={status || 'active'}
          onChange={handleStatusFilterChange}
          labelWidth={40}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="removed">Removed</MenuItem>
          <MenuItem value="expired">Expired</MenuItem>
          <MenuItem value="sold">Sold</MenuItem>
        </Select>
      </FormControl>

      <FormControl className={classes.filterItem}>
        <InputLabel id="agent-filter-label">Agent</InputLabel>
        <Select
          labelId="agent-filter-label"
          value={agent || 'all'}
          onChange={handleAgentFilterChange}
          labelWidth={40}
        >
          <MenuItem value="all">All agents</MenuItem>
          {
            agents.map(agent => (
              <MenuItem value={agent.id} key={agent.id}>{agent.name}</MenuItem>
            ))
          }
        </Select>
      </FormControl>
    </Box>
  );
};

export default OffersFilters;