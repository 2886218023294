import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Typography, Box } from "@material-ui/core";
import { RouteComponentProps } from "@reach/router";
import { useQueryParams, BooleanParam } from 'use-query-params';

import Layout from "../../components/Layout";
import InquiryCard from "./InquiryCard";
import CustomerInfo from "./CustomerInfo";
import Messages from './Messages';
import ActiveOffer from './ActiveOffer';
import PublicOffers from './PublicOffers';
import SendOfferModal from './SendOfferModal';
import { RootState } from "../../store";

import { actions } from '../../services/inquiryDetails';
import { actions as offerActions } from '../../services/offer';
import { OfferFormData, LeaseOfferSaveArgs } from "../../typings/types";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
        display: 'flex',
        flexDirection: 'column',
      },

      leaseData: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
      }
    }
  ),
);

interface Props extends RouteComponentProps {
  inqId: string;
}

const InquiryDetailsPage: React.FC<Props> = ({ inqId }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.inquiryDetails);
  const offers = useSelector((state: RootState) => state.offer);

  const [ params, setParams ] = useQueryParams({
    messages: BooleanParam,
    sendOffer: BooleanParam
  });

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    dispatch(actions.loadData(inqId));
  }, [ inqId ]);

  const toggleMessages = () => {
    if (params.messages) {
      setParams({ messages: false }, 'pushIn');
    }
    else {
      setParams({ messages: true }, 'pushIn');
    }
  }

  const toggleSendOfferModal = () => {
    if (params.sendOffer) {
      setParams({ sendOffer: false }, 'pushIn');
    }
    else {
      setParams({ sendOffer: true }, 'pushIn');
    }
  }

  const getOfferExpireTime = () => {
    const time = new Date();
    time.setHours(time.getHours() + 48);
    return time;
  }

  const newOfferData: OfferFormData | null = state.inquiry ? {
    trim: state.inquiry.trim,
    exteriorColor: state.inquiry.exteriorColor,
    interiorColor: state.inquiry.interiorColor,
    leaseLength: state.inquiry.leaseLength === 'not_sure' ? '36m' : state.inquiry.leaseLength,
    downPayment: state.inquiry.downPayment === 'not_sure' ? '1000' : state.inquiry.downPayment,
    mileage: state.inquiry.mileage === 'not_sure' ? '10000' : state.inquiry.mileage,
    monthlyPayment: 0,
    taxIncluded: true,
    additionalNotes: '',
    expireTime: getOfferExpireTime(),
  } : null;

  const onSubmitOffer = async (formData: OfferFormData) => {
    if (state.inquiry) {
      const args: LeaseOfferSaveArgs = {
        user: state.inquiry.user,
        inquiry: state.inquiry.id ? state.inquiry.id : '',
        car: state.inquiry.car,
        model: state.inquiry.model,
        trimData: state.inquiry.trimData,
        ...formData,
      }
      args.monthlyPayment = parseFloat(args.monthlyPayment.toString());
      const result = await dispatch(offerActions.submitOffer(args)) as any;
      if (result) {
        toggleSendOfferModal();
      }
    }
  }

  return (
    <Layout title="Inquiry Details" loading={state.loading}>
      {
        !state.inquiry || !newOfferData ? <div /> : (
          <div className={classes.root}>
            {
              state.inquiry.status === 'closed' ? (
                <Box mb={2}>
                  <Typography color="error" variant="h5" align="center">Inquiry closed</Typography>
                </Box>
              ) : null
            }

            <div className={classes.leaseData}>
              <CustomerInfo onMessagesClick={toggleMessages} onSendOfferClick={toggleSendOfferModal} />
              <InquiryCard />
            </div>
            <ActiveOffer />
            <PublicOffers />
            <Messages open={Boolean(params.messages)} onClose={toggleMessages} />
            <SendOfferModal
              open={Boolean(params.sendOffer)}
              onClose={toggleSendOfferModal}
              data={newOfferData}
              inquiry={state.inquiry}
              onSubmit={onSubmitOffer}
              pending={offers.submitOfferPending}
              variant="new" />
          </div>
        )
      }
    </Layout>
  );
};

export default InquiryDetailsPage;