import React, { useState } from "react";
import clsx from 'clsx';
import { isEmail, isURL } from 'validator';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Grid, TextField, Box, InputAdornment } from "@material-ui/core";
import MaskedInput from "../../components/MaskedInput";

import instagramIcon from './icons/instagram.svg';
import facebookIcon from './icons/facebook.svg';
import yelpIcon from './icons/yelp.png';
import youtubeIcon from './icons/youtube.svg';
import twitterIcon from './icons/twitter.svg';
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import ProgressButton from '../../components/ProgressButton';
import { CompanyInfoSaveArgs } from "../../typings/types";

import { actions } from '../../services/company';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      socialIcon: {
        width: 25,
        height: 25,
      },
      yelp: {
        fill: 'red',
      }
    }
  ),
);

const TabCompany: React.FC = () => {
  const classes = useStyles();

  const dealer = useSelector((state: RootState) => state.user.dealer);
  const loading = useSelector((state: RootState) => state.company.infoSaveLoading);

  const [ form, setForm ] = useState<CompanyInfoSaveArgs>({
    yelpId: dealer && dealer.yelpId ? dealer.yelpId : '',
    name: dealer ? dealer.name : '',
    phone: dealer ? dealer.phone : '',
    email: dealer && dealer.email ? dealer.email : '',
    website: dealer && dealer.website ? dealer.website : '',
    instagram: (dealer && dealer.social && dealer.social.instagram) ? dealer.social.instagram : '',
    facebook: (dealer && dealer.social && dealer.social.facebook) ? dealer.social.facebook : '',
    youtube: (dealer && dealer.social && dealer.social.youtube) ? dealer.social.youtube : '',
    twitter: (dealer && dealer.social && dealer.social.twitter) ? dealer.social.twitter : ''
  });


  const errorsDefault = {
    name: '',
    phone: '',
    email: '',
    website: '',
  };

  const [ errors, setErrors ] = useState(errorsDefault);

  const validateForm = () => {
    let valid = true;
    const errors = { ...errorsDefault };
    if (form.name === '') {
      errors.name = 'Dealer name required';
      valid = false;
    }
    if (form.email !== '' && !isEmail(form.email)) {
      errors.email = 'Invalid email';
      valid = false;
    }
    const phone = form.phone.replace(/ /g, '').replace(/_/g, '')
    if (phone.length !== 12) {
      errors.phone = 'Invalid phone';
      valid = false;
    }
    if (form.website !== '' && !isURL(form.website)) {
      errors.website = 'Invalid URL';
      valid = false;
    }

    setErrors(errors);

    return valid;
  }

  const dispatch = useDispatch();


  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [ name ]: event.target.value });
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (validateForm()) {
      dispatch(actions.saveCompanyInfo(form));
    }
  }


  return (
    <div className={classes.root}>
      <form onSubmit={onSubmit} noValidate>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              variant="outlined"
              id="companyName"
              name="companyName"
              label="Dealer Name"
              fullWidth
              autoComplete="companyName"
              value={form.name}
              onChange={handleChange('name')}
              error={errors.name !== ''}
              helperText={errors.name}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <MaskedInput
              required
              mask="+1 999 999 9999"
              variant="outlined"
              fullWidth
              label="Phone number"
              name="phone-number"
              autoComplete="phone"
              value={form.phone}
              onChange={handleChange('phone')}
              error={errors.phone !== ''}
              helperText={errors.phone}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <TextField
              variant="outlined"
              id="email"
              name="email"
              label="Email"
              fullWidth
              autoComplete="email"
              value={form.email}
              onChange={handleChange('email')}
              error={errors.email !== ''}
              helperText={errors.email}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="website"
              name="website"
              label="Website"
              fullWidth
              autoComplete="website"
              value={form.website}
              onChange={handleChange('website')}
              error={errors.website !== ''}
              helperText={errors.website}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="instagram"
              name="instagram"
              label="Instagram"
              fullWidth
              autoComplete="instagram"
              placeholder="@username"
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <img src={instagramIcon} alt="Instagram" className={classes.socialIcon} />
                </InputAdornment>,
              }}
              value={form.instagram}
              onChange={handleChange('instagram')}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="facebook"
              name="facebook"
              label="Facebook"
              fullWidth
              autoComplete="facebook"
              placeholder="@page"
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <img src={facebookIcon} alt="Facebook" className={classes.socialIcon} />
                </InputAdornment>,
              }}
              value={form.facebook}
              onChange={handleChange('facebook')}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="youtube"
              name="youtube"
              label="Youtube"
              fullWidth
              autoComplete="youtube"
              placeholder="https://youtube.com/channel"
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <img src={youtubeIcon} alt="Youtube" className={classes.socialIcon} />
                </InputAdornment>,
              }}
              value={form.youtube}
              onChange={handleChange('youtube')}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="twitter"
              name="twitter"
              label="Twitter"
              fullWidth
              autoComplete="twitter"
              placeholder="@username"
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <img src={twitterIcon} alt="Twitter" className={classes.socialIcon} />
                </InputAdornment>,
              }}
              value={form.twitter}
              onChange={handleChange('twitter')}
              disabled={loading}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="yelpId"
              name="yelpId"
              label="Yelp Business ID"
              fullWidth
              placeholder="yelp business id"
              InputProps={{
                startAdornment: <InputAdornment position="start">
                  <img src={yelpIcon} alt="Yelp" className={clsx(classes.socialIcon, classes.yelp)} />
                </InputAdornment>,
              }}
              value={form.yelpId}
              onChange={handleChange('yelpId')}
              disabled={loading}
            />
          </Grid>

          <Grid item xs>
            <Box display="flex" justifyContent="center" alignItems="center">
              <ProgressButton
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
              >
                Save Company Info
              </ProgressButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default TabCompany;