import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';
import { CompanyInfoSaveArgs } from '../typings/types';
import firebase, { db } from '../firebase';

import { actions as notification } from './notifications';
import { Address } from 'leasemojo-common';


interface CompanyProfile {
  infoSaveLoading: boolean;
  addressSaveLoading: boolean;
  logoUploadLoading: boolean;
}

const initialState: CompanyProfile = {
  infoSaveLoading: false,
  addressSaveLoading: false,
  logoUploadLoading: false,
};


const copmanyService = createSlice({
  name: 'company',
  initialState,
  reducers: {
    setInfoSaveLoading(state, action: PayloadAction<boolean>): CompanyProfile {
      return {
        ...state,
        infoSaveLoading: action.payload,
      }
    },
    setAddressSaveLoading(state, action: PayloadAction<boolean>): CompanyProfile {
      return {
        ...state,
        addressSaveLoading: action.payload,
      }
    },
    setLogoUploadLoading(state, action: PayloadAction<boolean>): CompanyProfile {
      return {
        ...state,
        logoUploadLoading: action.payload,
      }
    },
  }
});


const saveCompanyInfo = (data: CompanyInfoSaveArgs): AppThunk => async (dispatch, getState) => {
  dispatch(copmanyService.actions.setInfoSaveLoading(true));
  const dealer = getState().user.dealer;
  if (!dealer) {
    return;
  }
  try {
    const { name, phone, email, website, instagram, facebook, youtube, twitter, yelpId } = data
    await db.collection('dealers').doc(dealer.id).update({
      name,
      phone,
      email,
      website,
      yelpId,
      social: {
        instagram,
        facebook,
        youtube,
        twitter,
      },
      updateTime: firebase.firestore.FieldValue.serverTimestamp()
    });
    dispatch(notification.show({ message: 'Company profile updated' }));
  }
  catch (e) {
    dispatch(notification.show({ message: 'Error while saving', variant: 'error' }));
    console.error(e);
  }
  finally {
    dispatch(copmanyService.actions.setInfoSaveLoading(false));
  }
}

const saveAddress = (data: Address): AppThunk => async (dispatch, getState) => {
  const dealer = getState().user.dealer;
  if (!dealer) {
    return;
  }
  dispatch(copmanyService.actions.setAddressSaveLoading(true));
  try {
    await db.collection('dealers').doc(dealer.id).update({
      address: {
        ...data
      },
      updateTime: firebase.firestore.FieldValue.serverTimestamp()
    });
    dispatch(notification.show({ message: 'Address updated' }));
  }
  catch (e) {
    dispatch(notification.show({ message: 'Error while saving', variant: 'error' }));
    console.error(e);
  }
  finally {
    dispatch(copmanyService.actions.setAddressSaveLoading(false));
  }
}

const uploadLogo = (file: File): AppThunk => async (dispatch, getState) => {
  const dealer = getState().user.dealer;
  if (!dealer) {
    return;
  }

  dispatch(copmanyService.actions.setLogoUploadLoading(true));
  try {
    const ext = file.name.toLowerCase().split('.').splice(-1)[ 0 ];
    const storage = firebase.storage().ref();
    const timeStamp = new Date().getTime();
    const logoRef = storage.child(`/dealers/${dealer.id}/logo/${timeStamp}.${ext}`);
    await logoRef.put(file);
    dispatch(notification.show({ message: 'Logo uploaded' }));
  }
  catch (e) {
    console.error(e);
    dispatch(notification.show({ message: 'Error uploading logo', variant: 'error' }));
  }
  finally {
    dispatch(copmanyService.actions.setLogoUploadLoading(false));
  }
}


export const actions = {
  saveCompanyInfo,
  saveAddress,
  uploadLogo
};
export default copmanyService.reducer;