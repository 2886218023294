import React, { useRef } from "react";

import { navigate } from '@reach/router';

import { makeStyles, createStyles } from "@material-ui/styles";
import {
  Theme,
  ButtonGroup,
  Button,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  MenuItem,
} from "@material-ui/core";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';


import { useSelector, useDispatch } from "react-redux";
import { useQueryParam, BooleanParam } from "use-query-params";

import { RootState } from "../../store";
import { useDialog } from "../../components/DialogProvider";
import { OfferFormData, LeaseOfferUpdateArgs } from "../../typings/types";
import { actions as offerActions } from "../../services/offer";
import SendOfferModal from "./SendOfferModal";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
    }
  ),
);

const ActiveOfferActions: React.FC = () => {
  const classes = useStyles();

  const state = useSelector((state: RootState) => state.inquiryDetails);
  const agent = useSelector((state: RootState) => state.user.agent);
  const submitOfferPending = useSelector((state: RootState) => state.offer.submitOfferPending);
  const dispatch = useDispatch();
  const dialog = useDialog();

  const anchorRef = useRef<HTMLDivElement>(null);


  const [ menuOpen, setMenuOpen ] = React.useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  }

  const [ updateOfferModal, setUpdateOfferModal ] = useQueryParam('updateOffer', BooleanParam);

  const { inquiry, activeOffer } = state;

  if (!inquiry || !agent) {
    return null;
  }

  const updateOfferFormData: OfferFormData | null = activeOffer ? {
    trim: activeOffer.trim,
    expireTime: new Date(activeOffer.expireTime as number),
    exteriorColor: activeOffer.exteriorColor,
    interiorColor: activeOffer.interiorColor,
    leaseLength: activeOffer.leaseLength,
    downPayment: activeOffer.downPayment,
    mileage: activeOffer.mileage,
    monthlyPayment: activeOffer.monthlyPayment,
    taxIncluded: activeOffer.taxIncluded,
    additionalNotes: activeOffer.additionalNotes
  } : null;

  const notMyActiveOffer = activeOffer && activeOffer.agent !== agent.id ? true : false;

  const closeUpdateOffer = () => {
    if (updateOfferModal) {
      setUpdateOfferModal(false, 'replaceIn');
    }
  }

  const openUpdateOffer = () => {
    if (!updateOfferModal) {
      setUpdateOfferModal(true, 'pushIn');
    }
  }

  const removeOffer = () => {
    toggleMenu();
    dialog.confirm({
      title: 'Remove offer?',
      subtitle: `Are you sure you want to remove your offer for ${inquiry.carData && inquiry.carData.name} ${inquiry.modelData && inquiry.modelData.name}?`,
      onConfirm: () => {
        if (activeOffer && activeOffer.id) {
          dispatch(offerActions.removeOffer(activeOffer.id));
        }
      }
    })
  }

  const markAsSold = () => {
    toggleMenu();
    dialog.confirm({
      title: 'Mask as sold?',
      subtitle: `Client will be notified to confirm, and once it's done the inquiry will be closed?`,
      onConfirm: () => {
        if (activeOffer && activeOffer.id) {
          dispatch(offerActions.markAsSold(activeOffer.id));
        }
      }
    })
  }

  const markAsActive = () => {
    toggleMenu();
    if (activeOffer && activeOffer.id) {
      dispatch(offerActions.markAsActive(activeOffer.id));
    }
  }

  const onSubmitOffer = async (formData: OfferFormData) => {
    if (inquiry && activeOffer && activeOffer.id) {
      const args: LeaseOfferUpdateArgs = {
        id: activeOffer.id,
        inquiry: inquiry.id ? inquiry.id : '',
        ...formData,
      }
      args.monthlyPayment = parseFloat(args.monthlyPayment.toString());
      const result = await dispatch(offerActions.updateOffer(args)) as any;
      if (result) {
        closeUpdateOffer();
      }
    }
  }

  const buildMenu = () => {
    if (activeOffer?.status === 'active') {
      return (
        [
          <MenuItem onClick={removeOffer} key="remove">
            Remove offer
          </MenuItem>,
          <MenuItem onClick={markAsSold} key="sold">
            Mark as sold
          </MenuItem>
        ]
      )
    }
    if (activeOffer?.status === 'sold') {
      return (
        <MenuItem onClick={markAsActive}>
          Mark as active
        </MenuItem>
      );
    }
  }

  const openPublishModal = () => {
    navigate('/sales?dialog=1&offer=' + activeOffer?.id);
  }

  const menuDisabled = Boolean(activeOffer && ![ 'active', 'sold' ].includes(activeOffer.status));

  return (
    <div className={classes.root}>
      <ButtonGroup variant="outlined" ref={anchorRef}>
        {
          activeOffer?.status === 'sold' ? (
            <Button disabled={activeOffer.published} onClick={openPublishModal}>{activeOffer.published ? 'Published' : 'Publish'}</Button>
          ) : null
        }
        {
          activeOffer?.status === 'active' ? (
            <Button onClick={openUpdateOffer}>Update</Button>
          ) : null
        }
        <Button size="small" onClick={toggleMenu} disabled={menuDisabled}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>
      <Popper open={menuOpen} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={toggleMenu}>
                <MenuList>
                  {
                    buildMenu()
                  }
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
      {updateOfferFormData ?
        <SendOfferModal
          open={Boolean(updateOfferModal) && Boolean(updateOfferFormData)}
          onClose={closeUpdateOffer}
          data={updateOfferFormData}
          inquiry={inquiry}
          onSubmit={onSubmitOffer}
          pending={submitOfferPending}
          readOnly={notMyActiveOffer}
          variant="update" /> : null}
    </div>
  );
};

export default ActiveOfferActions;