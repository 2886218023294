import React, { useState } from "react";
import { isEmail } from 'validator';

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, TextField, Typography } from "@material-ui/core";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Box from '@material-ui/core/Box';
import Switch from '@material-ui/core/Switch';

import Layout from "../components/Layout";
import { RouteComponentProps } from "@reach/router";
import ProgressButtton from '../components/ProgressButton';
import MaskedInput from "../components/MaskedInput";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../store";
import { actions as userActions } from '../services/user';


const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      paper: {
        padding: theme.spacing(2, 4),
      },
      bottom: {
        textAlign: 'center',
        marginTop: theme.spacing(4),
      },
      passwordField: {
        width: '100%',
        margin: '20px 0px'
      },
    }
  ),
);

const MyProfilePage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => state.user);


  const [ formState, setFormState ] = useState({
    phone: state.agent ? state.agent.phone : '',
    email: state.agent ? state.agent.email : '',
    name: state.agent ? state.agent.name : '',
    password: '',
    reAuthPass: '',
  });

  const [ changePassword, setChangePassword ] = useState(false);

  const [ errors, setErrors ] = useState({
    phone: '',
    email: '',
    name: '',
    password: '',
  });

  const toggleChangePassword = () => {
    setFormState({
      ...formState,
      password: '',
    });
    setErrors({
      ...errors,
      password: ''
    });
    setChangePassword(!changePassword)
  };

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormState({ ...formState, [ name ]: event.target.value });
  };

  const validate = () => {
    const _err = {
      phone: '',
      email: '',
      name: '',
      password: '',
    };
    let valid = true;
    const _phone = formState.phone.replace(/ /g, '').replace(/_/g, '')
    if (Boolean(formState.phone) && _phone.length !== 12) {
      _err.phone = 'Invalid phone';
      valid = false;
    }
    if (!formState.email || !isEmail(formState.email)) {
      _err.email = 'Invalid email';
      valid = false;
    }
    if (!formState.name) {
      _err.name = 'Name is required';
      valid = false;
    }

    if (changePassword && formState.password.length < 6) {
      _err.password = 'Password should be at least 6 charecters';
      valid = false;
    }

    setErrors(_err);
    return valid;
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    if (!validate()) {
      return;
    }


    dispatch(userActions.updateProfile({
      name: formState.name,
      email: formState.email,
      phone:
        formState.phone.replace(/ /g, '').replace(/_/g, ''),
      password: changePassword ? formState.password : undefined,
    }));

  }

  const onReAuth = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    dispatch(userActions.reAuth(formState.reAuthPass));
  }

  const closeReAuth = () => {
    dispatch(userActions.setReAuthOpen(false));
  }

  return (
    <Layout title="My Profile" contentSize="sm">
      <Paper className={classes.paper}>
        <Typography variant="h4" align="center" paragraph>{state.dealer && state.dealer.name}</Typography>
        <Typography variant="body2" align="center" paragraph>My Profile {state.agent && state.agent.manager ? ' (manager)' : ''}</Typography>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <div>
            <TextField
              fullWidth
              id="standard-basic"
              label="Name"
              margin="normal"
              variant="outlined"
              value={formState.name}
              disabled={state.updateProfileInProgress}
              onChange={handleChange('name')}
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name : null}
            />
          </div>
          <div>
            <MaskedInput
              mask="+1 999 999 9999"
              variant="outlined"
              margin="normal"
              fullWidth
              label="Phone number"
              name="phone-number"
              autoComplete="phone"
              value={formState.phone}
              disabled={state.updateProfileInProgress}
              onChange={handleChange('phone')}
              error={Boolean(errors.phone)}
              helperText={errors.phone ? errors.phone : null}
            />
          </div>
          <div>
            <TextField
              fullWidth
              id="outlined-basic"
              label="Email"
              margin="normal"
              variant="outlined"
              value={formState.email}
              disabled={state.updateProfileInProgress}
              onChange={handleChange('email')}
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email : null}
            />
          </div>
          <Box display="flex" justifyContent="flex-end">
            <FormControlLabel
              control={
                <Switch checked={changePassword} onChange={toggleChangePassword} value="checkedA" />
              }
              label="Change password"
            />
          </Box>

          <TextField
            fullWidth
            type="password"
            id="outlined-basic"
            label="New password"
            margin="normal"
            variant="outlined"
            value={formState.password}
            disabled={!changePassword || state.updateProfileInProgress}
            onChange={handleChange('password')}
            error={Boolean(errors.password)}
            helperText={errors.password ? errors.password : null}
          />

          <div className={classes.bottom}>
            <ProgressButtton
              type="submit"
              variant="contained"
              color="primary"
              loading={state.updateProfileInProgress}
            >
              Save
            </ProgressButtton>
          </div>
        </form>
      </Paper>

      <Dialog
        open={state.reAuthOpen}
        onClose={closeReAuth}
        aria-labelledby="Please confirm your password"
      >
        <form onSubmit={onReAuth}>
          <DialogTitle>Please confirm your current password</DialogTitle>
          <DialogContent>
            <TextField
              type="password"
              label="Current password"
              variant="outlined"
              className={classes.passwordField}
              value={formState.reAuthPass}
              onChange={handleChange('reAuthPass')}
              error={state.reAuthError}
              helperText={state.reAuthError ? 'Invalid password' : null}
              autoFocus />
          </DialogContent>
          <DialogActions>
            <Button onClick={closeReAuth} color="primary">
              Cancel
          </Button>
            <ProgressButtton type="submit" color="primary" loading={state.reAuthInProgress}>
              Confirm
          </ProgressButtton>
          </DialogActions>
        </form>
      </Dialog>
    </Layout>
  );
};

export default MyProfilePage;