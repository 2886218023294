import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk } from '../store';

type NotificationType = 'info' | 'error' | 'new_inquiry';

interface NotificationData {
  key: string;
  message: string;
  dismissed: boolean;
  type?: NotificationType,
  targetUrl?: string,
  autoHideDuration?: number;
}

type NotificationsState = NotificationData[];

const initialState: NotificationsState = [];


const notificationService = createSlice({
  name: 'notifications',
  initialState,
  reducers: {
    add(state, action: PayloadAction<NotificationData>) {
      return [
        ...state,
        action.payload,
      ]
    },
    dismiss(state, action: PayloadAction<string>) {
      return state.map(item => {
        return {
          ...item,
          dismissed: item.key === action.payload
        }
      })
    },
    remove(state, action: PayloadAction<string>) {
      return state.filter(item => item.key !== action.payload);
    },
  }
});


const show = (args: { message: string, variant?: NotificationType, autoHideDuration?: number, targetUrl?: string }): AppThunk => async dispatch => {
  const key = new Date().getTime() + '-' + Math.random().toString();
  const data: NotificationData = {
    key: key,
    dismissed: false,
    message: args.message,
    type: args.variant, 
    targetUrl: args.targetUrl,
    autoHideDuration: args.autoHideDuration ? args.autoHideDuration : 2000,
  }
  dispatch(notificationService.actions.add(data));
}


export const actions = {
  show,
  ...notificationService.actions,
};
export default notificationService.reducer;