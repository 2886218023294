import React, { useState } from "react";
import { Link } from '@reach/router';
import clsx from 'clsx';

import { RouteComponentProps } from "@reach/router";
import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, TableSortLabel, Typography, Box, Button, Badge, Avatar } from "@material-ui/core";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Layout from "../components/Layout";

import CarLogo from "../components/CarLogo";
import { timeAgo, getExteriorColorIndex, getInteriorColorIndex } from 'leasemojo-common';
import TrimImage from "../components/TrimImage";
import { CreditScoreLabels, DownPaymentLabels } from 'leasemojo-common';

import { useSelector } from "react-redux";
import { RootState } from "../store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
      tableWrapper: {
        maxWidth: `calc(100vw - ${theme.spacing(4)}px)`,
      },
      paper: {
        // padding: theme.spacing(2),
        overflowX: 'auto',
      },
      table: {
        minWidth: 1000,
      },
      visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
      },
      imgCellH: {
        display: 'flex',
        alignItems: 'center',
        width: 100,
        '& img': {
          marginRight: theme.spacing(1),
        }
      },
      imgCellV: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: 100,

        '& img': {
          marginBottom: theme.spacing(0.5),
        }
      },
      activeOffer: {

      },
      avatar: {
        marginBottom: theme.spacing(1)
      }
    }
  ),
);

interface Column {
  id: string;
  title: string;
  align: 'right' | 'left' | 'center';
  sortable: boolean;
}

const columns: Column[] = [
  { id: 'client', title: 'Client', align: 'left', sortable: false, },
  { id: 'time', title: 'Time', align: 'left', sortable: false, },
  { id: 'car', title: 'Car', align: 'left', sortable: false, },
  { id: 'model', title: 'Model', align: 'left', sortable: false, },
  { id: 'colors', title: 'Colors', align: 'left', sortable: false, },
  { id: 'terms', title: 'Terms', align: 'left', sortable: false, },
  { id: 'offers', title: 'Offers', align: 'center', sortable: false, },
  { id: 'actions', title: '', align: 'center', sortable: false, },
]

type SortOrder = 'asc' | 'desc';

const InquiriesPage: React.FC<RouteComponentProps> = () => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => {
    return {
      inquiries: state.inquiries,
      dealer: state.user.dealer,
    }
  });

  const [ orderBy, setOrderBy ] = useState<SortOrder>('asc');
  const [ sortColumn, setSortColumn ] = useState(columns[ 0 ].id);

  const createSortHandler = (col: string) => (event: React.MouseEvent<unknown>) => {
    if (col !== sortColumn) {
      setSortColumn(col);
    }
    else {
      setOrderBy(orderBy === 'asc' ? 'desc' : 'asc');
    }
  };

  if (!state.dealer) {
    return null;
  }

  const renderContent = () => {

    return (
      <Paper className={classes.paper}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table} aria-label="customized table">
            <TableHead>
              <TableRow>
                {
                  columns.map((col, index) => (
                    <TableCell
                      key={index}
                      align={col.align}
                      sortDirection={sortColumn === col.id ? orderBy : false}
                    >
                      {
                        col.sortable ? (
                          <TableSortLabel
                            active={sortColumn === col.id}
                            direction={orderBy}
                            onClick={createSortHandler(col.id)}
                          >
                            {col.title}
                            {sortColumn === col.id ? (
                              <span className={classes.visuallyHidden}>
                                {orderBy === 'desc' ? 'sorted descending' : 'sorted ascending'}
                              </span>
                            ) : null}
                          </TableSortLabel>
                        ) : col.title
                      }
                    </TableCell>
                  ))
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {state.inquiries.inquiries.map((row, index) => {
                if (!row.carData || !row.modelData || !row.trimData) {
                  return null;
                }

                const time = timeAgo(row.createTime as number);

                const activeOffer = state.dealer && Boolean(row.offers[ state.dealer.id ]);

                let exteriorColor = 'No preference';
                let interiorColor = 'No preference';
                if (row.exteriorColor !== 0) {
                  const index = getExteriorColorIndex(row.exteriorColor, row.trimData.exteriorColors);
                  exteriorColor = row.trimData.exteriorColors[ index ].name;
                }
                if (row.interiorColor !== 0) {
                  const index = getInteriorColorIndex(row.interiorColor, row.trimData.interiorColors);
                  interiorColor = row.trimData.interiorColors[ index ].name;
                }

                return (
                  <TableRow key={row.carData.name + index} className={clsx({ [ classes.activeOffer ]: activeOffer })}>
                    <TableCell align={columns[ 0 ].align}>
                      <Box display="flex" flexDirection="column" alignItems="center">
                        <Avatar src={row.userInfo?.avatar} className={classes.avatar}/>
                        {`${row.userInfo?.firstName} ${row.userInfo?.lastName}`}
                      </Box>
                    </TableCell>
                    <TableCell align={columns[ 1 ].align}>{time}</TableCell>
                    <TableCell scope="row" align={columns[ 2 ].align}>
                      <div className={classes.imgCellV}>
                        <CarLogo car={row.carData} />
                        {row.carData.name}
                      </div>
                    </TableCell>
                    <TableCell align={columns[ 3 ].align}>
                      <div className={classes.imgCellV}>
                        <Typography>{row.modelData.name}</Typography>
                        <TrimImage trim={row.trimData} size={120} color={row.exteriorColor || row.trimData.defaultColors.exteriorColorId} placeholder={row.modelData.placeholderImage} />
                        <Typography variant="body2" align="center">{row.trimData.name}</Typography>
                      </div>
                    </TableCell>
                    <TableCell align={columns[ 4 ].align}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption">Exterior</Typography>
                        <Typography paragraph>{exteriorColor}</Typography>
                        <Typography variant="caption">Interior</Typography>
                        <Typography>{interiorColor}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align={columns[ 5 ].align}>
                      <Box display="flex" flexDirection="column">
                        <Typography variant="caption">Credit Score</Typography>
                        <Typography paragraph>{CreditScoreLabels.get(row.creditScore)}</Typography>
                        <Typography variant="caption">Down payment</Typography>
                        <Typography paragraph>{DownPaymentLabels.get(row.downPayment)}</Typography>
                      </Box>
                    </TableCell>
                    <TableCell align={columns[ 6 ].align}>
                      {
                        row.id ? (
                          <div>
                            <Typography variant="caption">Offers</Typography>
                            <Typography paragraph>{Object.keys(row.offers).length}</Typography>
                          </div>
                        ) : Object.keys(row.offers).length
                      }
                    </TableCell>
                    <TableCell align={columns[ 7 ].align}>
                      <Badge color="secondary" variant="dot" invisible={!activeOffer}>
                        <Button component={Link} to={`/inquiry/${row.id}`} variant="outlined">Details</Button>
                      </Badge>
                    </TableCell>
                  </TableRow>
                );

              })}
            </TableBody>
          </Table>
        </div>
      </Paper>
    );
  }

  return (
    <Layout title="Inquiries" className={classes.root} loading={state.inquiries.loading}>
      {
        renderContent()
      }
    </Layout>
  );
};

export default InquiriesPage;