import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Grid, TextField, Box } from "@material-ui/core";
import { Address } from "leasemojo-common";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../store";
import ProgressButton from '../../components/ProgressButton';

import { actions } from '../../services/company';

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      root: {
      },
    }
  ),
);

const TabAddress: React.FC = () => {
  const classes = useStyles();

  const dealer = useSelector((state: RootState) => state.user.dealer);
  const loading = useSelector((state: RootState) => state.company.addressSaveLoading);

  const [ form, setForm ] = useState<Address>({
    address1: dealer && dealer.address ? dealer.address.address1 : '',
    address2: dealer && dealer.address ? dealer.address.address2 : '',
    city: dealer && dealer.address ? dealer.address.city : '',
    state: 'CA',
    country: 'USA',
    zip: dealer && dealer.address ? dealer.address.zip : '',
  })

  const defaultErrors = {
    address1: '',
    city: '',
    zip: '',
  };

  const [ errors, setErrors ] = useState(defaultErrors);

  const dispatch = useDispatch();

  const handleChange = (name: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setForm({ ...form, [ name ]: event.target.value });
  };


  const validateForm = () => {
    let valid = true;
    const errors = { ...defaultErrors };
    if (form.address1 === '') {
      valid = false;
      errors.address1 = 'Required';
    }
    if (form.city === '') {
      valid = false;
      errors.city = 'Required';
    }
    if (form.zip === '' || isNaN(parseInt(form.zip as string))) {
      valid = false;
      errors.zip = 'Invalid zip';
    }
    setErrors(errors);
    return valid;
  }

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();
    if (validateForm()) {
      dispatch(actions.saveAddress(form));
    }
  }

  return (
    <div className={classes.root}>
      <form noValidate onSubmit={onSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              required
              variant="outlined"
              id="address1"
              name="address1"
              label="Address line 1"
              fullWidth
              autoComplete="address-line1"
              value={form.address1}
              onChange={handleChange('address1')}
              disabled={loading}
              error={errors.address1 !== ''}
              helperText={errors.address1}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              id="address2"
              name="address2"
              label="Address line 2"
              fullWidth
              autoComplete="address-line2"
              value={form.address2}
              onChange={handleChange('address2')}
              disabled={loading}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              id="city"
              name="city"
              label="City"
              fullWidth
              autoComplete="address-level2"
              value={form.city}
              onChange={handleChange('city')}
              disabled={loading}
              error={errors.city !== ''}
              helperText={errors.city}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              id="state"
              name="state"
              label="State"
              fullWidth
              variant="outlined"
              value="CA"
              disabled
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              id="zip"
              name="zip"
              label="Zip / Postal code"
              fullWidth
              autoComplete="postal-code"
              value={form.zip}
              onChange={handleChange('zip')}
              disabled={loading}
              error={errors.zip !== ''}
              helperText={errors.zip}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              required
              variant="outlined"
              id="country"
              name="country"
              label="Country"
              fullWidth
              autoComplete="country"
              value="USA"
              disabled
            />
          </Grid>
          <Grid item xs>
            <Box display="flex" justifyContent="center" alignItems="center" mt={3}>
              <ProgressButton
                type="submit"
                variant="contained"
                color="primary"
                loading={loading}
              >
                Save
              </ProgressButton>
            </Box>
          </Grid>
        </Grid>
      </form>
    </div>
  );
};

export default TabAddress;