import React, { useState } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Chip } from "@material-ui/core";
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { useSelector } from "react-redux";
import { RootState } from "../../store";

import ActiveOfferActions from './ActiveOfferActions';

import {
  timeAgo,
  LeaseLengthLabels,
  LeaseMileageLabels,
  DownPaymentLabels,
  getExteriorColorIndex,
  getInteriorColorIndex,
} from 'leasemojo-common';
import clsx from "clsx";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      scroller: {
        maxWidth: 'calc(100vw - 40px)',
        overflowX: 'auto',
      },
      root: {
        minWidth: 900,
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(4),
      },
      panel: {
        '&:before': {
          display: 'none',
        },
      },
      progressContainer: {
        flex: 1,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },

      summary: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        flex: 1,
      },
      summaryItem: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: theme.spacing(3),
        '&.expanded': {
          flex: 1,
        },
      },
    }
  ),
);

interface SummaryProps {
  title: string;
  value?: string;
  expanded?: boolean;
}

const SummaryItem: React.FC<SummaryProps> = ({ title, value, expanded }) => {
  const classes = useStyles();

  return (
    <div className={clsx(classes.summaryItem, { 'expanded': expanded })}>
      <Typography variant="caption">{title}</Typography>
      <Typography variant="body1">{value}</Typography>
    </div>
  )
}

const ActiveOffer: React.FC = () => {
  const classes = useStyles();
  const state = useSelector((state: RootState) => state.inquiryDetails);
  const agent = useSelector((state: RootState) => state.user.agent);
  const [ expanded, setExpanded ] = useState(false);


  const { inquiry, activeOffer } = state;

  if (!inquiry || !agent) {
    return null;
  }

  const hasActiveOffer = Boolean(inquiry.offers[ agent.dealer ]);

  if (!hasActiveOffer) {
    return null;
  }

  const toggleExpanded = () => {
    setExpanded(!expanded);
  }

  let extColorIndex = 0;
  let intColorIndex = 0;
  if (activeOffer && activeOffer.trimData) {
    extColorIndex = getExteriorColorIndex(activeOffer.exteriorColor, activeOffer.trimData.exteriorColors);
    intColorIndex = getInteriorColorIndex(activeOffer.interiorColor, activeOffer.trimData.interiorColors);
  }

  return (
    <div className={classes.scroller}>
      <div className={classes.root}>
        <Typography variant="subtitle1">Active offer</Typography>
        <ExpansionPanel expanded={expanded} onChange={toggleExpanded} className={classes.panel}>
          <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
            {
              !activeOffer ? <div className={classes.progressContainer}><CircularProgress /></div> : (
                <div className={classes.summary}>
                  {
                    activeOffer.status === 'sold' ? <Chip label="Sold" color="secondary" /> : null 
                  }
                  {
                    activeOffer.status === 'expired' ? <Chip label="Expired" color="secondary" /> : null 
                  }
                  {
                    activeOffer.status === 'removed' ? <Chip label="Removed" color="secondary" /> : null 
                  }
                  <SummaryItem title="Time" value={timeAgo(activeOffer.createTime as number * 1000)} />
                  <SummaryItem title="Agent" value={activeOffer.agentData && activeOffer.agentData.name} />
                  <SummaryItem title="Trim" value={activeOffer.trimData && activeOffer.trimData.name} />
                  <SummaryItem title="Lease Length" value={LeaseLengthLabels.get(activeOffer.leaseLength)} />
                  <SummaryItem title="Down" value={DownPaymentLabels.get(activeOffer.downPayment)} />
                  <SummaryItem title="Mileage" value={LeaseMileageLabels.get(activeOffer.mileage)} />
                  <SummaryItem title="Monthly" value={`$${activeOffer.monthlyPayment}`} />
                </div>
              )
            }
          </ExpansionPanelSummary>
          <ExpansionPanelDetails>
            <SummaryItem title="Tax included" value={activeOffer?.taxIncluded ? 'Yes' : 'No'} />
            <SummaryItem title="Exterior" value={activeOffer?.trimData?.exteriorColors[ extColorIndex ].name} />
            <SummaryItem title="Interior" value={activeOffer?.trimData?.interiorColors[ intColorIndex ].name} />
            <SummaryItem title="Notes" value={activeOffer?.additionalNotes} expanded />
            <ActiveOfferActions />
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </div>
    </div>
  );
};

export default ActiveOffer;