import React, { useState, useEffect } from "react";

import { makeStyles, createStyles } from "@material-ui/styles";
import { Theme, Paper, IconButton, Button, CircularProgress } from "@material-ui/core";

import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Close';
import { useDialog } from "../../../components/DialogProvider";
import { PhotoSource } from "../../../typings/types";
import { SoldPhotoData } from "leasemojo-common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles(
    {
      paper: {
        position: 'relative',
        width: 150,
        height: 150,
        display: 'flex',
        flex: '0 0 auto',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 5,
        marginRight: theme.spacing(1),
        backgroundSize: 'cover',
        overflow: 'hidden',
      },

      removeIcon: {
        position: 'absolute',
        top: theme.spacing(1),
        right: theme.spacing(1),
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        color: '#fff',

        '&:hover': {
          backgroundColor: 'rgba(0, 0, 0, 0.4)',
        }
      },
      progressCover: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }
    }
  ),
);

interface Props {
  index: number;
  src: PhotoSource;
  onRemove: (index: number, type: 'local' | 'remote') => void;
  progress?: number;
  loading?: boolean;
}

const PhotoItem: React.FC<Props> = ({ src, onRemove, index, loading, progress }) => {
  const classes = useStyles();
  const dialog = useDialog();

  const [ imgSrc, setImgSrc ] = useState();

  const isFile = (src as File).name || null;
  const isPhoto = (src as SoldPhotoData).original || null;

  useEffect(() => {
    if (isFile) {
      previewFile(src as File);
    }
    else if (isPhoto) {
      setImgSrc((src as SoldPhotoData).src || (src as SoldPhotoData).original);
    }
  }, [ isFile, isPhoto ]);

  const previewFile = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && e.target.result) {
        setImgSrc(e.target.result);
      }
    }
    reader.readAsDataURL(file);
  }

  const _onRemove = () => {
    dialog.confirm({
      title: 'Please confirm',
      subtitle: 'Are you sure, you want to delete this photo?',
      onConfirm: () => {
        onRemove && onRemove(index, isFile ? 'local' : 'remote');
      }
    })
  }

  const bgStyle: any = {};

  if (isFile) {
    bgStyle[ 'backgroundImage' ] = `url('${imgSrc}')`;
  }
  else if (isPhoto && (src as SoldPhotoData).src) {
    bgStyle[ 'backgroundImage' ] = `url('${process.env.REACT_APP_CDN_BASE_MAIN}/${(src as SoldPhotoData).src}')`;
  }
  else if (isPhoto) {
    bgStyle[ 'backgroundImage' ] = `url('${process.env.REACT_APP_CDN_BASE_MAIN}/${(src as SoldPhotoData).original}')`;
  }

  return (
    <Paper className={classes.paper} style={bgStyle}>
      {
        !loading ? (
          <IconButton className={classes.removeIcon} size="small" onClick={_onRemove}>
            <RemoveIcon />
          </IconButton>
        ) : null
      }

      {
        loading ? (
          <div className={classes.progressCover}>
            <CircularProgress
              variant={!progress ? 'indeterminate' : 'static'}
              value={progress}
              color="secondary" />
          </div>
        ) : null
      }

    </Paper>
  );
};

export default PhotoItem;