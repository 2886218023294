import React, { useState, useContext } from "react";

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';




const Context = React.createContext({
  confirm: (args: ConfirmArgs) => { }
});


interface ConfirmArgs {
  title: string;
  subtitle?: string;
  onConfirm: () => void;
  onCancel?: () => void;
}

const DialogProvider: React.FC = ({ children }) => {

  const [ state, setState ] = useState({
    open: false,
    title: '',
    subtitle: '',
    onConfirm: () => { },
    onCancel: () => { },
  })






  const confirm = (args: ConfirmArgs) => {
    setState({
      open: true,
      title: args.title,
      subtitle: args.subtitle || '',
      onConfirm: args.onConfirm,
      onCancel: args.onCancel || (() => { })
    });
  }

  const handleClose = () => {
    setState({
      ...state,
      open: false,
      onConfirm: () => { },
      onCancel: () => { },
    })
  }

  const onConfirm = () => {
    state.onConfirm();
    handleClose();
  }

  const onCancel = () => {
    state.onCancel();
    handleClose();
  }

  return (
    <React.Fragment>
      <Context.Provider value={{ confirm }}>
        {children}
      </Context.Provider>
      <Dialog
        fullWidth
        open={state.open}
        onClose={handleClose}
        aria-labelledby={state.title}
        aria-describedby={state.subtitle}
      >
        <DialogTitle>{state.title}</DialogTitle>
        <DialogContent>
          {
            state.subtitle ? (
              <DialogContentText>
                {state.subtitle}
              </DialogContentText>
            ) : null
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button onClick={onConfirm} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  );
};

export default DialogProvider;


export const useDialog = () => {
  const context = useContext(Context);

  return context;
}